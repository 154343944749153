import { Component } from '@angular/core';
import { updateConfigData } from '../../../config';
import { TestService } from '../../../services/test.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { config } from '../../../config';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';
import { commonConfig } from 'src/app/config/common/common';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { Role, UserProfile, User } from 'src/types';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Subscription } from 'rxjs';
import { RoleService } from '../../../services/user/role.service';
declare const $: any;

export interface MappedProfile {
  forename: string | null;
  surname: string | null;
  DOB: string | null;
}

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent {
  consents;
  isLoading = false;
  hide = true;
  signInForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(commonConfig.pattern.email),
    ]),
    password: new FormControl('', [Validators.required]),
  });
  email_address = '';
  password = '';
  isPwdInfo = true;
  user;
  config: any = config;
  subscription: Subscription;
  error: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private testService: TestService,
    private ngxService: NgxUiLoaderService,
    private roleService: RoleService
  ) {
  }

  getFriendlyErrorMessage(errorCode: string): string {
    const errorMessages = {
      'auth/user-disabled': 'Your account has been disabled. Please contact support.',
      'auth/user-not-found': 'No account found with this email address.',
      'auth/wrong-password': 'Incorrect password. Please try again.',
      'auth/invalid-login-credentials': 'Invalid email or password.',
      default: 'An unexpected error occurred. Please try again later.',
    };

    return errorMessages[errorCode] || errorMessages.default;
  }

  async onSignIn() {

    this.ngxService.start();

    if (!this.isFormValid()) {
      this.ngxService.stop();
      return;
    }

    try {
      const userCredential = await this.authenticateUser();
      const idToken = await userCredential.user.getIdToken(true);
      const { userId } = AuthService.decodeJWT(idToken);
      const user = await this.userService.getUser(userId);
      const userProfile = await this.setUserProfile(userCredential);
      this.setLocalStorageData(user, userProfile);
      this.postLoginActions(userProfile);
    } catch (error) {
      this.handleError(error);
    }
  }

  isFormValid(): boolean {
    return this.signInForm.valid;
  }

  async authenticateUser() {
    const auth = getAuth();
    const { email, password } = this.signInForm.value;

    return await signInWithEmailAndPassword(auth, email, password);
  }

  async setUserProfile(userCredential) {
    const idToken = await userCredential.user.getIdToken(true);
    const { userId } = AuthService.decodeJWT(idToken);
    const userProfile = await this.userService.getUserProfile(userId);

    this.user = {
      username: `${userProfile.firstName} ${userProfile.lastName}`,
      displayName: `${userProfile.firstName} ${userProfile.lastName}`,
      email: userProfile.email,
      roleId: 1,
      UUID: userId,
      userId,
      newUser: '',
      fname: userProfile.firstName,
      sname: userProfile.lastName,
    };

    updateConfigData(userCredential);

    return userProfile;
  }

  mapRoleToLegacyRoleId = (roles: Role[]): number => {
    const roleMapping: { [roleName: string]: number } = {
      admin: 3001,
      provider: 2001,
      subscriber: 1001,
    };

    for (const roleName of Object.keys(roleMapping)) {
      if (roles.some((role) => role === roleName)) {
        return roleMapping[roleName];
      }
    }
  };

  mapUserProfileToLegacyUser(profile: UserProfile): MappedProfile {
    return {
      forename: profile.firstName,
      surname: profile.lastName,
      DOB: profile.dateOfBirth,
    };
  }

  setLocalStorageData(user: User, userProfile: UserProfile) {
    const { userId }: any = userProfile;

    localStorage.setItem(
      'loggedInUser',
      JSON.stringify(this.mapUserProfileToLegacyUser(userProfile))
    );
    localStorage.setItem(
      'patient',
      JSON.stringify(this.mapUserProfileToLegacyUser(userProfile))
    );
    localStorage.setItem('UUID', JSON.stringify(userId));
    localStorage.setItem('patientId', JSON.stringify(userId));
    localStorage.setItem(
      'roleId',
      JSON.stringify(this.mapRoleToLegacyRoleId(user.roles))
    );
  }


  postLoginActions(userProfile) {
    this.testService.loginSuccess.emit(true);
    this.ngxService.stop();

    let redirect: string;

    if (this.roleService.isProvider) {
      redirect = '/my-subscribers';
    } else {
      redirect = this.userService.isProfileComplete(userProfile) ? '/' : '/account/subject';
    }

    const queryParamsRedirect = this.activatedRoute.snapshot.queryParams?.redirect;
    this.router.navigate([queryParamsRedirect || redirect]);
  }

  handleError(error: any) {
    this.ngxService.stop();
    this.error = this.getFriendlyErrorMessage(error.code)
  }

  doRouting(path) {
    this.testService.doRouting(path);
  }

  requestForgotPassword() {
    this.testService.forgotPassword.emit(true);
  }

}
