import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import * as moment from 'moment';

import { BaseComponent } from '../../../modules/shared/base.component';
import { UserService } from '../../../services/user/user.service';
import { Option } from '../../../../types';
import { SelectedSubscriberService } from 'src/app/services/selected-subscriber/selected-subscriber.service';

@Component({
  selector: 'app-personal-info-form',
  templateUrl: './personal-info-form.component.html',
  styleUrls: ['./personal-info-form.component.css'],
})
export class PersonalInfoFormComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() canChangePatientId = true;
  form: FormGroup;
  genderOptions: Option[] = [];

  isUpdatingProfile = false;
  isSucceeded = false;
  isFailed = false;
  errors = { patientId: null };

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private selectedSubscriberService: SelectedSubscriberService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.subscriptions.add(
      combineLatest([
        this.selectedSubscriberService.getUserDataObservable(),
        this.userService.getGenderOptions(),
      ]).subscribe({
        next: ([userData, options]) => {
          const {
            userId,
            patientId,
            gender,
            email,
            firstName,
            lastName,
            dateOfBirth,
          } = userData?.userProfile || {};

          if (!this.isUpdatingProfile) {
            this.form.patchValue({
              userId,
              patientId,
              email,
              firstName,
              lastName,
              dateOfBirth: dateOfBirth
                ? moment(dateOfBirth, 'YYYY-MM-DD')
                : undefined,
              gender,
            });
          }
          this.genderOptions = options;
        },
      })
    );

    this.subscriptions.add(
      this.form.valueChanges.subscribe(() => {
        this.isSucceeded = false;
        this.isFailed = false;
      })
    );
  }

  ngOnChanges(_changes: { [propertyName: string]: SimpleChange }) {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      userId: [null],
      patientId: [
        { value: null, disabled: !this.canChangePatientId },
        [Validators.required],
      ],
      email: [{ value: null, disabled: true }, [Validators.required]],
      firstName: [null, [Validators.required, this.noNumbersValidator()]],
      lastName: [null, [Validators.required, this.noNumbersValidator()]],
      dateOfBirth: [null, [Validators.required]],
      gender: [null, [Validators.required]],
    });

    this.form.get('firstName').valueChanges.subscribe((value) => {
      this.removeNumbersFromControl('firstName', value);
    });

    this.form.get('lastName').valueChanges.subscribe((value) => {
      this.removeNumbersFromControl('lastName', value);
    });
  }

  private removeNumbersFromControl(controlName: string, value: string) {
    if (value) {
      const sanitizedValue = value.replace(/\d/g, '');
      if (sanitizedValue !== value) {
        this.form
          .get(controlName)
          .setValue(sanitizedValue, { emitEvent: false });
      }
    }
  }

  private noNumbersValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = /\d/.test(control.value);
      return forbidden ? { noNumbers: { value: control.value } } : null;
    };
  }

  removeError(field:string) {
    delete this.errors[field];
    this.form.get(field).setErrors(null);
    this.form.updateValueAndValidity();
  }

  onSubmit() {
    this.isUpdatingProfile = true;
    this.isSucceeded = false;
    this.isFailed = false;

    const { userId, patientId, gender, firstName, lastName, dateOfBirth } =
      this.form.value;

    this.form.markAsPristine();

    const data = {
      ...(this.canChangePatientId ? { patientId: patientId?.trim() } : {}),
      gender,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      dateOfBirth: dateOfBirth.format('YYYY-MM-DD'),
    };

    const subscribeId = JSON.parse(localStorage.getItem('patientId'));
    const currentUserId = JSON.parse(localStorage.getItem('UUID'));

    this.subscriptions.add(
      this.userService
        .updateUserProfile(userId, data)
        .pipe(switchMap(() => this.userService.refreshUserProfile(userId)))
        .subscribe({
          next: async () => {
            this.userService.fetchFullUserData(currentUserId).subscribe(() => {
              this.isUpdatingProfile = false;
              this.selectedSubscriberService.setSelectedSubscriber(subscribeId);
              this.isSucceeded = true;
            });
          },
          error: (e) => {
            this.isUpdatingProfile = false;
            this.isFailed = true;
            const errObject = e?.error?.errors;
            this.errors = errObject;
            Object.keys(errObject)?.forEach(key => {
              this.form.controls[key].setErrors({ incorrect: true });
            })
          },
        })
    );
  }
}
