import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { commonConfig } from "../../../config/common/common";
import { TestService } from "../../../services/test.service";
import { config } from "src/app/config";
declare const zingchart: any;

@Component({
  selector: "app-mix",
  templateUrl: "./mix.component.html",
  styleUrls: ["./mix.component.css"],
})
export class MixComponent implements OnInit {
  @Input() mixedData: any;

  adaptiveAnalytes: any = [];
  adaptiveData: any;
  rangeData: any;
  selectedAdaptiveAnalyte: any;
  fullNames: any;

  chartId: string;
  selected: any = [
    "range",
    "maximumValue",
    "adaptiveMaxValue",
    "meanValue",
    "adaptiveMinValue",
    "minimumValue",
  ];

  selectedAll: any = {};
  selectedAdaptiveAnalytes: any = [];
  allSelected: boolean = false;

  constructor(
    private translate: TranslateService,
    private _testService: TestService
  ) {
  }

  ngOnInit(): void {
    this.initializeChartProperties();
    this.filterAdaptiveAnalytes();
    this.setDefaultAdaptiveAnalyte();
    this.cloneSelectedData();
  }

  private initializeChartProperties(): void {
    this.chartId = this.mixedData.chartId;
    this.adaptiveData = this.mixedData.adaptiveData;
    this.rangeData = this.mixedData.rangeData;
  }

  private filterAdaptiveAnalytes(): void {
    const adaptiveAnalytes = this.mixedData.adaptiveAnalytes.filter(
      element2 => this.mixedData.analyteSequence.includes(element2)
    );

    this.adaptiveAnalytes = this.sortArrayBySequence(config.analyteSequenceArray, adaptiveAnalytes);
  }

  sortArrayBySequence(sequence: string[], arrayToSort: string[]): string[] {
    const sequenceMap = new Map<string, number>();
    sequence.forEach((item, index) => sequenceMap.set(item, index));

    return arrayToSort.sort((a, b) => {
      const indexA = sequenceMap.get(a);
      const indexB = sequenceMap.get(b);

      return (indexA !== undefined ? indexA : Number.MAX_SAFE_INTEGER) -
        (indexB !== undefined ? indexB : Number.MAX_SAFE_INTEGER);
    });
  }


  private setDefaultAdaptiveAnalyte(): void {
    this.selectedAdaptiveAnalyte = this.adaptiveAnalytes[0];
  }

  private cloneSelectedData(): void {
    this.fullNames = this.mixedData.fullNames;
    this.adaptiveAnalytes.forEach(item => {
      this.selectedAll[item] = { ...this.selected };
    });
  }

  ngAfterViewInit() {
    this.renderAdaptiveChart(this.selectedAdaptiveAnalyte, this.chartId);
  }

  renderAdaptiveCharts(analytes) {
    const selectedAdaptiveAnalytes = this.selectedAdaptiveAnalytes.slice();
    const newAdded = analytes.value.filter(item => !selectedAdaptiveAnalytes.includes(item));
    const removed = selectedAdaptiveAnalytes.filter(item => !analytes.value.includes(item));
    this.removeAnalytes(removed);
    this.addAndRenderAnalytes(newAdded);
  }

  async removeAnalytes(removedAnalytes) {
    for (const analyte of removedAnalytes) {
      const index = this.selectedAdaptiveAnalytes.indexOf(analyte);
      if (index > -1) {
        this.selectedAdaptiveAnalytes.splice(index, 1);
      }
    }
  }

  async addAndRenderAnalytes(newAddedAnalytes) {
    for (const analyte of newAddedAnalytes) {
      this.selectedAdaptiveAnalytes.push(analyte);
      await this.renderAdaptiveChart(analyte, `${analyte}_chartId`);
    }
  }

  selectAnalyte(analyte) {
    if (this.allSelected) {
      analyte = typeof analyte === "string" ? analyte : analyte.value;
    } else {
      this.selectedAdaptiveAnalyte = typeof analyte === "string" ? analyte : analyte.value;
      analyte = this.selectedAdaptiveAnalyte;
    }
    return analyte
  }

  async renderAdaptiveChart(analyte, chartId, element?) {
    analyte = this.selectAnalyte(analyte);
    const analyteRange = this.rangeData.find((p) => p.analytes === analyte);

    let allMax;
    let allMin;

    if (analyteRange) {
      allMax = Array(this.adaptiveData[analyte].HEALTH_ID.length).fill(analyteRange.max);
      allMin = Array(this.adaptiveData[analyte].HEALTH_ID.length).fill(analyteRange.min);
    }

    const dates = this.adaptiveData[analyte].DATE.map(date => Math.round(new Date(date.replace(" ", "T")).getTime()));

    const { ADAPTIVE_MAX, MEAN, VALUE, ADAPTIVE_MIN } = this.adaptiveData[analyte];

    const maximumValue = dates.map((time, index) => [time, Number(allMax[index])]);
    const adaptiveMaxValue = dates.map((time, index) => [time, Number(ADAPTIVE_MAX[index])]);
    const meanValue = dates.map((time, index) => [time, Number(MEAN[index])]);
    const actualValue = dates.map((time, index) => [time, Number(VALUE[index])]);
    const adaptiveMinValue = dates.map((time, index) => [time, Number(ADAPTIVE_MIN[index])]);
    const minimumValue = dates.map((time, index) => [time, Number(allMin[index])]);

    const markerRange = dates.map((time, index) => [
      time,
      [ADAPTIVE_MIN[index], ADAPTIVE_MAX[index]],
    ]);

    const padding = (allMax[0] - allMin[0]) * 0.2;
    let customTooltipText = "<p>%kl</p>";
    const res = await Promise.all([
      this._testService.getTranslation("reports.minimumValue"),
      this._testService.getTranslation("reports.adaptiveMinValue"),
      this._testService.getTranslation("reports.mean"),
      this._testService.getTranslation("reports.actualValue"),
      this._testService.getTranslation("reports.adaptiveMaxValue"),
      this._testService.getTranslation("reports.maximumValue"),
      this._testService.getTranslation("reports.range"),
      this._testService.getTranslation("reports.units"),
      this._testService.getTranslation("reports.meanValue"),
    ]);

    if (typeof this.selectedAll[analyte] === 'object') {
      this.selectedAll[analyte] = Object.values(this.selectedAll[analyte]);
    }

    const selected = this.allSelected
      ? JSON.parse(JSON.stringify(this.selectedAll[analyte]))
      : JSON.parse(JSON.stringify(this.selected));

    const data = selected.map(element => {
      let config;
      switch (element) {
        case "minimumValue":
          config = this.createMinimumValueConfig(res, minimumValue, commonConfig);
          customTooltipText += `<p>${config.text}: %data-${element}</p>`;
          break;
        case "adaptiveMinValue":
          config = this.createAdaptiveMinValueConfig(res, adaptiveMinValue, commonConfig);
          customTooltipText += `<p>${config.text}: %data-${element}</p>`;
          break;
        case "meanValue":
          config = this.createMeanValueConfig(res, meanValue, commonConfig);
          customTooltipText += `<p>${config.text}: %data-${element}</p>`;
          break;
        case "actualValue":
          config = this.createActualValueConfig(res, actualValue, commonConfig, VALUE, allMax, allMin, ADAPTIVE_MAX, ADAPTIVE_MIN, MEAN, analyteRange);
          customTooltipText += `<p>${config.text}: %data-${element}</p>`;
          break;
        case "adaptiveMaxValue":
          config = this.createAdaptiveMaxValueConfig(res, adaptiveMaxValue, commonConfig);
          customTooltipText += `<p>${config.text}: %data-${element}</p>`;
          break;
        case "maximumValue":
          config = this.createMaximumValueConfig(res, maximumValue, commonConfig);
          customTooltipText += `<p>${config.text}: %data-${element}</p>`;
          break;
        case "range":
          config = this.createRangeConfig(res, markerRange, commonConfig);
          break;
      }
      return config;
    });

    const chartConfig = this.createChartConfig(customTooltipText, res, analyte, this.rangeData, allMin, allMax, padding, data);
    this.renderOrUpdateChart(chartId, chartConfig, element);
  }

  createMinimumValueConfig(res, minimumValue, commonConfig) {
    return {
      type: "line",
      values: minimumValue,
      text: res[0],
      lineColor: commonConfig.colors.green1,
      lineWidth: "3px",
      lineStyle: "dashed",
      tooltip: {
        visible: false,
      },
      marker: {
        backgroundColor: "white",
        borderColor: commonConfig.colors.green1,
        shadow: false,
        visible: false,
        size: "0px",
      },
      shadow: false,
    };
  }

  createAdaptiveMinValueConfig(res, adaptiveMinValue, commonConfig) {
    return {
      text: res[1],
      type: "line",
      values: adaptiveMinValue,
      lineColor: commonConfig.colors.green2,
      lineWidth: "1.5px",
      tooltip: {
        visible: false,
      },
      marker: {
        backgroundColor: "white",
        borderColor: commonConfig.colors.green2,
        shadow: false,
        visible: false,
        size: "0",
      },
      shadow: false,
    };
  }

  createMeanValueConfig(res, meanValue, commonConfig) {
    return {
      text: res[8],
      type: "line",
      values: meanValue,
      lineColor: commonConfig.colors.green3,
      lineWidth: "1px",
      lineStyle: "dashed",
      tooltip: {
        visible: false,
      },
      marker: {
        backgroundColor: "white",
        borderColor: commonConfig.colors.green3,
        shadow: false,
        visible: false,
        size: "0",
      },
      shadow: false,
    };
  }

  createActualValueConfig(res, actualValue, commonConfig, VALUE, allMax, allMin, ADAPTIVE_MAX, ADAPTIVE_MIN, MEAN, analyteRange) {
    return {
      text: res[3],
      type: "line",
      values: actualValue,
      lineColor: commonConfig.colors.blue,
      lineWidth: "0px",
      marker: {
        backgroundColor: commonConfig.colors.blue,
        borderColor: commonConfig.colors.blue,
        shadow: false,
        size: "3px",
      },
      shadow: false,
      "data-actualValue": VALUE,
      "data-maximumValue": allMax,
      "data-minimumValue": allMin,
      "data-adaptiveMaxValue": ADAPTIVE_MAX,
      "data-adaptiveMinValue": ADAPTIVE_MIN,
      "data-meanValue": MEAN,
      "data-markerRange": analyteRange
    };
  }

  createAdaptiveMaxValueConfig(res, adaptiveMaxValue, commonConfig) {
    return {
      text: res[4],
      type: "line",
      values: adaptiveMaxValue,
      lineColor: commonConfig.colors.greenLight,
      lineWidth: "1.5px",
      tooltip: {
        visible: false,
      },
      marker: {
        backgroundColor: "white",
        borderColor: commonConfig.colors.greenLight,
        shadow: false,
        visible: false,
        size: "0",
      },
      shadow: false,
    };
  }

  createMaximumValueConfig(res, maximumValue, commonConfig) {
    return {
      type: "line",
      text: res[5],
      values: maximumValue,
      lineColor: commonConfig.colors.green4,
      lineWidth: "3px",
      lineStyle: "dashed",
      tooltip: {
        visible: false,
      },
      marker: {
        backgroundColor: "white",
        borderColor: commonConfig.colors.green4,
        shadow: false,
        visible: false,
        size: "0",
      },
      shadow: false,
    };
  }

  createRangeConfig(res, markerRange, commonConfig) {
    return {
      type: "range",
      text: res[6],
      values: markerRange,
      tooltip: {
        visible: false,
      },
      legend: {
        visible: false,
      },
      backgroundColor: commonConfig.colors.green5,
      alphaArea: 0.5,
      guideLabel: {
        visible: false,
      },
      lineWidth: "0px",
      lineColor: commonConfig.colors.green5,
      marker: {
        visible: false,
      },
    };
  }

  createChartConfig(customTooltipText, res, analyte, rangeData, allMin, allMax, padding, data) {
    const index = rangeData.findIndex(p => p.analytes === analyte);

    const unit = index !== -1 ? rangeData[index].unit : '';
    return {
      type: "mixed",
      plot: {
        tooltip: {
          text: customTooltipText,
          "html-mode": true,
          zIndex: 100,
        },
      },
      plotarea: {
        margin: 60,
      },
      scaleX: {
        transform: {
          type: "date",
          all: "%dd %M %Y &nbsp;&nbsp;&nbsp;&nbsp;<br> %h:%i %A",
        },
        zooming: true,
      },
      scaleY: {
        label: {
          text: `${res[7]} ${unit}`,
          color: "#2D2D2D",
          fontWeight: "none",
        },
        guide: {
          visible: false,
        },
        minValue: allMin[0] - padding,
        maxValue: allMax[0] + padding,
      },
      preview: {
        adjustLayout: true,
        live: true,
      },
      series: data.filter(config => config),
    };
  }

  renderOrUpdateChart(chartId, chartConfig, element) {
    if (document.getElementById(`${chartId}-wrapper`)) {
      zingchart.exec(chartId, "modify", {
        data: chartConfig,
      });

      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        }, 200);
      }
    } else {
      setTimeout(() => {
        zingchart.render({
          id: chartId,
          data: chartConfig,
          height: "100%",
          width: "100%",
        });
      }, 500);
    }
  }

  selectType(type, analyte) {
    const foundIndex = this.selected.indexOf(type);
    foundIndex > -1
      ? delete this.selected[foundIndex]
      : this.selected.unshift(type);
    this.renderAdaptiveChart(analyte, this.chartId);
  }

  selectTypes(type, analyte) {

    const foundIndex = this.selectedAll[analyte].indexOf(type);
    foundIndex > -1
      ? delete this.selectedAll[analyte][foundIndex]
      : this.selectedAll[analyte].unshift(type);

    let element = document.getElementById(`${analyte}_select`);

    this.renderAdaptiveChart(analyte, `${analyte}_chartId`, element);
  }

  getBorderColor(type, analyte?) {
    if (analyte) {
      return this.selectedAll[analyte].indexOf(type) > -1 ? true : false;
    } else {
      return this.selected.indexOf(type) > -1 ? true : false;
    }
  }

  viewType() {
    this.allSelected = !this.allSelected ? true : false;
    if (this.allSelected) {
      this.selectedAdaptiveAnalyte = null;
      this.selectedAdaptiveAnalyte = this.adaptiveAnalytes;
      this.selectedAdaptiveAnalytes = [];
      this.renderAdaptiveCharts({ value: this.adaptiveAnalytes });
    } else {
      this.selectedAdaptiveAnalytes = [];
      this.selectedAdaptiveAnalyte = this.adaptiveAnalytes[0];
      this.renderAdaptiveChart(this.selectedAdaptiveAnalyte, this.chartId);
    }
  }

  isSelected(analyte) {
    if (typeof this.selectedAll[analyte] === 'object') {
      this.selectedAll[analyte] = Object.values(this.selectedAll[analyte]);
    }

    return this.selectedAll && this.selectedAll[analyte]?.includes("actualValue") || false;
  }
}
