<div class="row m-0 p-0 flex-row-reverse">
  <div
    class="col-12 col-md-7 d-flex flex-wrap align-content-center justify-content-center py-4"
    id="custom-section"
  >
    <ngx-ui-loader></ngx-ui-loader>
    <!-- Logo Section -->

    <div class="col-12" style="padding-top: 50px !important">
      <div class="algocyte_logo_square mx-auto" (click)="doRouting(['/home'])">
        <img
          src="assets/algocyte/logo.png"
          alt="company-logo"
          class="img-fluid"
        />
      </div>
    </div>

    <!-- Page Title -->
    <div *ngIf="!isVerifySuccess && !verifyError" class="col-12">
      <div class="sign_in_text mx-auto margin-bottom-20">
        <!-- {{getTitle()}} -->
        {{ 'verificationComponent.pageTitle' | translate }}
      </div>
    </div>

    <div *ngIf="isVerifySuccess" class="col-12">
      <div class="sign_in_text mx-auto margin-bottom-20">
        <!-- {{getTitle()}} -->
        {{ 'verificationComponent.pageTitleVerified' | translate }}
      </div>
    </div>

    <!-- Verify your account message -->

    <div *ngIf="!verifyError && !isVerifySuccess">
      <div class="text-center mt-4" *ngIf="!resendVerificationStatus">
        <div class="mb-2">
          We've sent you an email to verify your email address and activate your
          account.
        </div>
        <div class="mb-2">
          <a
            href="#"
            class="resend-link"
            (click)="resendVerificationEmail($event)"
            >Click here if you did not receive an email</a
          >
        </div>
      </div>

      <div class="text-center mt-4" *ngIf="resendVerificationStatus">
        <div class="mb-2">
          An email has been sent the email address you signed up with.
        </div>
        <div class="mb-2">
          Please check your inbox and click on the link to verify your email
          address.
          <br />
          If you do not see the email, please check your spam folder or contact
          support.
        </div>
      </div>
    </div>

    <div *ngIf="!verifyError && isVerifySuccess">
      <div class="text-center mt-4" *ngIf="!resendVerificationStatus">
        <div class="mb-2">Your email has been verified successfully.</div>
        <div class="mb-2">
          <br />
          <button
            (click)="doRouting(['/account/subject'])"
            mat-button
            class="go-to-dashboard text-uppercase"
            type="submit"
          >
            {{ 'verificationComponent.goToProfileBtnTxt' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="verifyError" class="text-center mt-4">
      <div class="mb-2">
        We are unable to verify your email address. Please contact support.
      </div>
      <div class="mb-2">Error: {{ verifyError }}</div>
    </div>

    <div class="w-100 text-center padding-top-0">
      <app-resource-links></app-resource-links>
    </div>
  </div>

  <div class="col-12 col-md-5" style="padding: 0">
    <app-auth-aside
      welcomeText="{{ 'signUp.welcomeStatement' | translate }}"
      welcomeSubText="{{ 'signUp.infoStatement' | translate }}"
      ctaText=" {{ 'common.signIn' | translate }}"
      ctaUrl="/auth/signin"
    >
    </app-auth-aside>
  </div>
</div>
