<footer class="footer_section">
  <div class="container">
    <div class="form-row py-4">
      <div
        class="col-md-9 d-md-flex flex-wrap align-content-center d-block text-md-left text-center"
      >
        <div class="footer_link">
          <ul class="p-0 mb-md-0 mb-3">
            <li class="mr-md-4 ml-md-0 mr-sm-2 ml-sm-2 mb-sm-0 mb-2">
              <a target="_blank" href="{{ config.termsAndConditionsLink }}">
                {{ 'menus.termAndConditions' | translate }}
              </a>
            </li>
            <li class="mr-md-4 ml-md-0 mr-sm-2 ml-sm-2 mb-sm-0 mb-2">
              <a target="_blank" href="{{ config.privacyPolicyLink }}">
                {{ 'menus.privacyPolicy' | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-3 d-block text-md-right text-center">
        <div class="social_link">
          <ul class="p-0 m-0">
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/algocyte/"
                ><i class="fab fa-linkedin fa-2x"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="m-0" />
    <div class="form-row py-3 footer-bottom">
      <div
        class="col-lg-6 mb-lg-0 mb-3 d-lg-flex flex-wrap align-content-center d-block text-lg-left text-center"
      >
        <p>
          {{ 'footer.copyRightLine' | translate: { year: currentYear } }} |
          {{ 'common.brandName' | translate }}&#169;
        </p>
      </div>
      <div class="col-lg-6">
        <div class="footer_right_content">
          <div>
            <img src="assets/help-img/ref.png" alt="" />
          </div>
          <div>
            <p>{{ 'common.brandName' | translate }}&#169;</p>
          </div>
          <div>
            <img src="assets/help-img/lot.png" alt="" />
          </div>
          <div>
            <p>
              {{ 'footer.version' | translate: { version: config.appVersion } }}
            </p>
          </div>
          <div>
            <img
              style="max-height: 35px"
              src="assets/help-img/information.svg"
              alt=""
            />
          </div>
        </div>
        <!-- <table class="table table-borderless w-auto ml-md-auto mr-md-0 m-auto" cellspacing="0" cellpadding="7">
          <tbody>
            <tr valign="center">
              <td class="p-0">
                <img class="mr-2" src="assets/help-img/ref.png" alt="" />
              </td>
              <td class="p-0">
                <p>{{'common.brandName' | translate}}&#169; Platform</p>
              </td>
              <td class="p-0">
                <img class="m-2" src="assets/help-img/lot.png" alt="" />
              </td>
              <td class="p-0">
                <p>{{ 'footer.version' | translate:{'version':config.appConfig.appVersion} }}</p>
              </td>
              <td class="p-0">
                <a>
                  <img style="max-height: 35px" src="assets/help-img/information.svg" alt="" />
                </a>
              </td>
              <td class="p-0" *ngIf="appConfig.footer.ceuk">
                <img src="assets/help-img/ce ukca-foot.png" alt="" />
              </td>
              <td class="p-0" *ngIf="appConfig.footer.ivd">
                <img src="assets/help-img/ivd.png" alt="" />
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>

      <div class="betaInformation">
        <p>
          {{ 'common.brandName' | translate }}&#169;
          {{ 'footer.betaInformation' | translate }}
        </p>
      </div>
      <!-- <div class="evalutionInformation">
        <p *ngIf="textNote !== ''">{{'footer.evalutionText' | translate}}</p>
      </div> -->
    </div>
  </div>
</footer>
