<p class="links-container">
  <a href="{{ config.termsAndConditionsLink }}" target="_blank" class="web-links">
    {{ 'menus.termAndConditions' | translate }}
  </a>
  <a href="{{ config.privacyPolicyLink }}" target="_blank" class="web-links">
    {{ 'menus.privacyPolicy' | translate }}
  </a>
  <a href="{{ config.cookiePolicyLink }}" target="_blank" class="web-links">
    {{ 'menus.cookiePolicy' | translate }}
  </a>
  <a (click)="downloadHelpPdf()" target="_blank" class="web-links">
    {{ 'menus.instructionsForUse' | translate }}
  </a>
  <a (click)="downloadQuickStartGuide()" target="_blank" class="web-links">
    {{ 'menus.quickStartGuide' | translate }}
  </a>
</p>