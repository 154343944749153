import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { commonConfig } from './../../../config/common/common';
import { HealthEvent } from '../../../../types';

declare const zingchart: any;
zingchart.TOUCHZOOM = 'pinch';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css'],
})
export class LineComponent implements OnInit, AfterViewInit {
  @Input() lineData: any;
  @Output() clickedTest: EventEmitter<any> = new EventEmitter();

  chartId: string;
  chartData: any;
  adaptiveScores: any;
  scoreType: string;
  selected: any = ['nonAdaptive'];
  allEventList: any = [];
  yMax: number = 0;
  showLineChart: boolean = false;
  score: any[];

  options: any = {
    type: 'line',
    plot: {
      tooltip: {
        text: `<p>%kl</p><p>%t: %v</p>`,
        'html-mode': true,
      },
    },
    scaleX: {
      transform: {
        type: 'date',
        all: '%dd %M %Y &nbsp;&nbsp;&nbsp;&nbsp;<br> %h:%i %A',
      },
      marginLeft: 50,
      zooming: true,
    },
    scaleY: {
      label: {
        text: this.translate.instant('reports.units'),
      },
    },
    preview: {
      adjustLayout: true,
      live: true,
    },
    series: [],
  };
  isAllSelected: boolean = true;
  isAllUnSelected: boolean = false;

  isReport: boolean = false;
  userRole: Number = JSON.parse(localStorage.getItem('roleId'));
  markers = commonConfig.markers;

  cellColors = commonConfig.cellColors;
  selectedAnalytes = [];
  deselectAllLineChart: Boolean = false;
  selectAllLineChart: Boolean = true;
  analytes: any = [];
  fullResponse: any = [];
  dataForRerenderdChart: any;
  dataForLineChart: any = [];
  isClinician: boolean = false;
  isPatient: boolean = false;

  isCovsAnalyte: boolean = false;
  isDefaultAnalyte: boolean = false;

  events: any = [];
  eventGroup: any = [];
  addUpScaleInMax: boolean;

  constructor(
    private translate: TranslateService,
    private _ngxService: NgxUiLoaderService,
    private changeRef: ChangeDetectorRef,
    private router: ActivatedRoute
  ) {
    this.setScoreType(this.router.snapshot.paramMap.get('scoreType'));
  }

  ngOnInit() {
    this.chartId = this.lineData.chartId;
    this.isClinician = this.userRole === 2001 ? true : false;
    this.isPatient = this.userRole === 1001 ? true : false;
  }

  ngAfterViewInit() {
    this.renderLineChart();
  }

  ngOnChanges() {
    this.chartId = this.lineData.chartId;
    this.scoreType = this.lineData.scoreType || 'Haematology';
    this.isReport =
      this.lineData && this.lineData.isReport ? this.lineData.isReport : false;
    this.addUpScaleInMax = !this.isReport ? true : false;
    this.showLineChart = false;

    this.allEventList = this.getEventTypesForFilter(this.lineData.events);

    if (this.isReport) {
      this.analytes = JSON.parse(JSON.stringify(this.lineData.analytes));
      this.fullResponse = JSON.parse(JSON.stringify(this.lineData.originalRes));
      this.setSelectedAnalytes();
      this.dataForRerenderdChart = JSON.parse(
        JSON.stringify(this.lineData.data)
      );

      this.filterAnalytesOnScoreType();
    }
    else {
      this.chartData = JSON.parse(JSON.stringify(this.lineData.data));
      this.adaptiveScores = JSON.parse(
        JSON.stringify(this.lineData.adaptiveScores)
      );

      this.options.series = this.getImmuneScoreData();
      this.renderLineChart(this.options);
    }

    zingchart.loadModules('scalableyaxis', (e) => {
      this.options['scalableYaxis'] = {
        scalable: true,
        chartid: this.chartId,
      };
    });

    this.displayEvents();
  }

  renderLineChart(options?) {
    if (options && options.scaleY && options.scaleY.maxValue)
      options.scaleY.maxValue = this.yMax;

    if (this.isReport) {
      this.renderChartForReportPage();
    } else {
      const data = {
        type: 'line',
        plot: {
          tooltip: {
            text: `<p>%kl</p><p>%t: %v</p>`,
            'html-mode': true,
          },
        },
        scaleX: {
          transform: {
            type: 'date',
            all: '%dd %M %Y &nbsp;&nbsp;&nbsp;&nbsp;<br> %h:%i %A',
          },
          zooming: true,
        },
        scaleY: {
          values: this.getYScaleValue(),
          label: {
            text: this.translate.instant('reports.units'),
          },
        },
        preview: {
          adjustLayout: true,
          live: true,
        },
        series: this.options.series,
      };
      if (document.getElementById('customLineChart-wrapper')) {
        zingchart.exec(this.chartId, 'modify', {
          data: data,
        });
      } else {
        zingchart.render({
          id: this.chartId,
          hideprogresslogo: true,
          data: data,
          height: 400,
          width: '100%',
          events: {
            touchstart: function (e) {
              e.preventDefault();
            },
            touchmove: function (e) {
              e.preventDefault();
            },
          },
        });
      }
    }
  }

  getYScaleValue() {
    const result = `0:${Math.ceil(Math.floor(this.yMax) + this.yMax / 10)}:${Math.ceil(Math.floor(this.yMax) + this.yMax / 10) / 10
      }`

    if (this.checkAllZeros(result)) {
      return `0:10:10`
    }
    else {
      return `0:${Math.ceil(Math.floor(this.yMax) + this.yMax / 10)}:${Math.ceil(Math.floor(this.yMax) + this.yMax / 10) / 10
        }`;
    }


  }

  checkAllZeros(inputString) {
    const parts = inputString.split(":");
    return parts.every(part => part === "0");
  }

  renderChartForReportPage() {
    zingchart.render({
      id: this.chartId,
      hideprogresslogo: true,
      data: {
        type: 'line',
        plot: {
          tooltip: {
            text: `<p>%kl</p><p>%t: %v</p>`,
            'html-mode': true,
          },
        },
        plotarea: {
          margin: 75,
        },
        scaleX: {
          transform: {
            type: 'date',
            all: '%dd %M %Y &nbsp;&nbsp;&nbsp;&nbsp;<br> %h:%i %A',
          },
          zooming: true,
        },
        scaleY: {
          values: this.getYScaleValue(),
          label: {
            text: this.translate.instant('reports.units'),
          },
          autoFit: [true],
        },
        preview: {
          adjustLayout: true,
          live: true,
        },
        series: this.options.series,
      },
      height: 600,
      width: '100%',
    });

    window['customFncs'] = {};

    zingchart.shape_click = (p) => {
      if (p.shapeid === 'deselect_all') {
        Promise.all(this.analytes).then((values) => {
          zingchart.exec(p.id, 'hideplot', {
            plotindex: this.analytes.indexOf(values),
          });
        });
      }
    };
    zingchart.node_click = (e) => {
      if (e.id === this.chartId) {
        this.clickedTest.emit({ healthId: e['data-healthId'] });
      }
    };
    window['customFncs'].formatTooltip1 = (p) => {
      const dataset = zingchart.exec(this.chartId, 'getdata');
      dataset.graphset[p.graphindex].series;
    };
  }

  getImmuneScoreData() {
    const data = [];
    this.selected.forEach((element) => {
      switch (element) {
        case 'nonAdaptive':
          data.push({
            text: this.translate.instant('reports.score'),
            values: this.getImmuneScoreSeriesData(this.chartData),
            lineColor: '#777676',
            lineWidth: '1px',
            marker: {
              backgroundColor: '#777676',
              borderColor: '#777676',
              shadow: false,
              size: '3px',
            },
            scales: 'scale-x,scale-y',
          });
          break;
        default:
          break;
      }
    });
    return data;
  }

  getImmuneScoreSeriesData(chartData) {
    const data = [];
    this.score = [];

    chartData.forEach((val) => {
      if (val.healthId && !isNaN(val.immuneScore)) {
        this.score.push(Number(val.immuneScore.toFixed(2)));
        data.push([
          Math.round(new Date(val.date.replace(' ', 'T')).getTime()),
          Number(val.immuneScore.toFixed(2)),
        ]);
      }
    });

    this.calculateYmax(data);
    return data;
  }

  selectType(type?) {
    if (type) {
      const foundIndex = this.selected.indexOf(type);

      if (foundIndex > -1) this.selected.splice(foundIndex, 1);
      else this.selected.push(type);
    }

    this.options.series = this.getImmuneScoreData();

    if (this.options.series && this.options.series.length) {
      // this.renderLineChart(this.options);
    } else {
      console.error('Error :: While Preapre Data Series for Chart  ');
    }

    this.displayEvents();
  }

  //for Report LineChart Data :: start
  setSelectedAnalytes() {
    this.selectedAnalytes = [];
    const analytesLength = this.analytes.length || 0;
    for (let i = 0; i < analytesLength; i++) {
      let markerIndex = 0;
      let colorIndex = 0;
      if (i <= this.markers.length - 1) markerIndex = i;
      else markerIndex = i % this.markers.length;

      if (i <= this.cellColors.length - 1) colorIndex = i;
      else colorIndex = i % this.cellColors.length;

      this.selectedAnalytes.push({
        name: this.analytes[i],
        selectd: true,
        marker: this.markers[markerIndex],
        color: this.cellColors[colorIndex],
      });
    }
  }

  getZingChartData(resForOldChart) {

    const data = [];
    this.yMax = 0;
    resForOldChart.cellTimeSeries.forEach((series, index) => {
      let selectedAnalytesIndex = this.selectedAnalytes.findIndex((element, index) => {
        if (element.name == Object.keys(series)[0]) {
          return !index ? '0' : index;
        }
      });

      if (series[Object.keys(series)[0]] && series[Object.keys(series)[0]].length > 0) {
        const maxSeriesValue = Math.max(...series[Object.keys(series)[0]]);
        this.yMax = this.yMax < maxSeriesValue ? maxSeriesValue : this.yMax;
      }

      if (series[Object.keys(series)[0]] && series[Object.keys(series)[0]].length > 0) {
        data.push({
          text: Object.keys(series)[0],
          values: this.getSeriesData(series[Object.keys(series)[0]], resForOldChart.dates),
          lineColor: this.selectedAnalytes[selectedAnalytesIndex]['color'],
          marker: {
            backgroundColor: this.selectedAnalytes[selectedAnalytesIndex]['color'],
            type: this.selectedAnalytes[selectedAnalytesIndex]['marker'],
            size: 5,
          },
          legendMarker: {
            size: 5,
            type: this.selectedAnalytes[selectedAnalytesIndex]['marker'],
          },
          'data-healthId': this.getSeriesHealthId(series[Object.keys(series)[0]], resForOldChart.dates),
          'data-actual': this.getSeriesActualValue(series[Object.keys(series)[0]], Object.keys(series)[0]),
          scales: 'scale-x,scale-y',
        });
      }
    });

    return data;
  }

  getSeriesData(series, dates) {
    const data = [];
    this.score = [];
    series.forEach((val, i) => {
      if (val !== null && val !== -1) {
        this.score.push(Number(val));
        data.push([
          Math.round(new Date(dates[i].replace(' ', 'T')).getTime()),
          val !== -1 ? Number(val) : null,
        ]);
      }
    });
    this.calculateYmax(data);
    return data;
  }

  getSeriesHealthId(series, dates) {
    const data = [];
    series.forEach((val, i) => {
      if (val !== null && !isNaN(val)) {
        data.push(this.fullResponse[i].healthId);
      }
    });
    return data;
  }

  getSeriesActualValue(series, dates) {
    const data = [];
    series.forEach((val, i) => {
      if (val !== null && !isNaN(val)) {
        data.push(this.fullResponse[i][dates]);
      }
    });
    return data;
  }

  selectAnalyte(i?) {
    this.selectAllLineChart = false;
    let data = {};
    if (i > -1)
      this.selectedAnalytes[i].selectd = this.selectedAnalytes[i].selectd
        ? false
        : true;

    let count = 0;

    this.selectedAnalytes.forEach((element) => {
      if (element.selectd) {
        count++;
        data[element.name] =
          this.dataForRerenderdChart['cellTimeSeries'][
          this.selectedAnalytes.indexOf(element)
          ][element.name];
      }
    });
    if (this.selectedAnalytes.length == count) {
      this.deselectAllLineChart = false;
      this.selectAllLineChart = true;
    } else if (count) {
      this.deselectAllLineChart = false;
      this.selectAllLineChart = false;
    } else {
      this.deselectAllLineChart = true;
      this.selectAllLineChart = false;
    }

    this.prepareLineChartData({
      dates: this.dataForRerenderdChart['dates'],
      cellTimeSeries: JSON.parse(JSON.stringify(data)),
    });

    this.displayEvents();
  }

  handleSelect(status) {
    delete this.options.series;
    if (status) {
      this.handleDeselectAll();
    } else {
      this.handleSelectAll();
    }

    setTimeout(() => {
      this.displayEvents();
    }, 500);

    this._ngxService.stop();
  }

  handleDeselectAll() {
    this.deselectAllLineChart = true;
    this.selectAllLineChart = false;
    this.selectedAnalytes.forEach((element) => {
      element.selectd = false;
    });

    this.prepareLineChartData({
      dates: this.dataForRerenderdChart['dates'],
      cellTimeSeries: {},
    });
    this.yMax = 0.5;
  }

  handleSelectAll() {
    let data = {};
    this.selectAllLineChart = true;
    this.deselectAllLineChart = false;
    this.selectedAnalytes.forEach((element) => {
      element.selectd = true;
      data[element.name] = this.dataForRerenderdChart['cellTimeSeries'][this.selectedAnalytes.indexOf(element)][element.name];
    });

    this.prepareLineChartData({
      dates: this.dataForRerenderdChart['dates'],
      cellTimeSeries: JSON.parse(JSON.stringify(data)),
    });
  }

  prepareLineChartData(resForChart) {
    this.dataForLineChart = [];
    this.analytes.forEach(async (cell, index) => {
      const data = {};
      data[cell] = resForChart.cellTimeSeries[cell];
      this.dataForLineChart.push(data);
    });

    resForChart.cellTimeSeries = this.dataForLineChart;

    this.options.series = this.getZingChartData(resForChart);
  }

  setScoreType(scoreType) {
    switch (scoreType) {
      case 'Haematology':
        this.isCovsAnalyte = false;
        this.isDefaultAnalyte = true;
        break;
      default:
        this.isCovsAnalyte = true;
        this.isDefaultAnalyte = true;
        break;
    }
  }

  //change selection according score type set
  filterAnalytesOnScoreType() {
    let data = {},
      count = 0;

    this.deselectAllLineChart = false;
    this.selectAllLineChart = false;

    const isDateChange =
      this.lineData && this.lineData.isDateChange
        ? this.lineData.isDateChange
        : false;
    if (!isDateChange) {
      this.selectedAnalytes.forEach((element) => {
        if (!this.isCovsAnalyte && this.isDefaultAnalyte)
          element.selectd = true;
        else if (this.isCovsAnalyte && this.isDefaultAnalyte)
          element.selectd = true;
        else element.selectd = false;
      });
    }

    this.selectedAnalytes.forEach((element) => {
      if (element.selectd) {
        count++;
        data[element.name] = JSON.parse(
          JSON.stringify(
            this.dataForRerenderdChart['cellTimeSeries'][
            this.selectedAnalytes.indexOf(element)
            ][element.name]
          )
        );
      }
    });


    if (this.selectedAnalytes.length === count) {
      this.selectAllLineChart = true
      this.deselectAllLineChart = false
    }
    else {
      this.selectAllLineChart = true
      this.deselectAllLineChart = false
    }

    this.prepareLineChartData({
      dates: this.dataForRerenderdChart['dates'],
      cellTimeSeries: JSON.parse(JSON.stringify(data)),
    });
  }

  calculateYmax(data) {
    data.forEach((element) => {
      this.yMax = element['1'] > this.yMax ? element['1'] : this.yMax;
    });

    if (this.addUpScaleInMax) {
      let percentage = this.yMax > 50 ? 0.5 : this.yMax > 20 ? 2.5 : 5;
      this.yMax = this.yMax + (this.yMax * percentage) / 100;
      this.addUpScaleInMax = false;
    }
  }

  selectEventType(i, type?) {
    this.changeRef.detectChanges();
    if (type && !this.isReport) {
      if (type == 'all') {
        this.isAllSelected = true;
        this.isAllUnSelected = false;
        this.allEventList.forEach((event: any) => {
          event.isSelected = true;
        });
        this.selectType();
      } else {
        this.isAllSelected = false;
        this.isAllUnSelected = true;
        this.allEventList.forEach((event: any) => {
          event.isSelected = false;
        });
        this.selectType();
      }
    } else if (type && this.isReport) {
      if (type == 'all') {
        this.isAllSelected = true;
        this.isAllUnSelected = false;
        this.allEventList.forEach((event: any) => {
          event.isSelected = true;
        });
        this.selectAnalyte();
      } else {
        this.isAllSelected = false;
        this.isAllUnSelected = true;
        this.allEventList.forEach((event: any) => {
          event.isSelected = false;
        });
        this.selectAnalyte();
      }
    } else {
      this.allEventList[i].isSelected = !this.allEventList[i].isSelected;
      if (this.isReport) this.selectAnalyte();
      else this.selectType();

      let selectedCount = 0;
      let unSelectedCount = 0;
      this.allEventList.forEach((event) => {
        if (!event.isSelected) {
          unSelectedCount++;
        } else {
          selectedCount++;
        }
      });

      if (this.allEventList.length === selectedCount) {
        this.isAllSelected = true;
        this.isAllUnSelected = false;
      } else if (this.allEventList.length === unSelectedCount) {
        this.isAllSelected = false;
        this.isAllUnSelected = true;
      } else {
        this.isAllSelected = false;
        this.isAllUnSelected = false;
      }
    }
  }

  // here get the border color based on the selected legend of graph
  getEventBorderColor(index, type?) {
    if (type) {
      if (type == 'Select All') {
        return false;
      } else {
        return false;
      }
    } else {
      return this.allEventList[index].isSelected;
    }
  }

  displayEvents() {
    const validEvents = this.prepareEventsForEventHorizon(this.lineData.events);
    const eventData = this.getGroupForEventHorizon(validEvents);

    this.options.series.push(...eventData);

    this.renderLineChart();
    zingchart.exec(this.chartId, 'addplot', {
      graphid: this.chartId,
      data: eventData,
    });
    this.showLineChart = true;
  }

  getEventTypesForFilter(events: HealthEvent[]) {
    const unique = [];

    events?.forEach((event) => {
      event.eventTypes.forEach((eventType) => {
        if (!unique.find((x) => x.id === eventType.id)) {
          unique.push({
            icon: `assets/health-event/${eventType.id}.svg`,
            label: eventType.name,
            id: eventType.id,
            isSelected: true, // true by default
          });
        }
      });
    });

    return unique;
  }

  prepareEventsForEventHorizon(events: HealthEvent[]) {
    const validEvents = [];

    events?.forEach((event) => {
      const currentDayEvents = [];

      event.eventTypes.forEach((eventType) => {
        const icon = this.isEventSelected(eventType.id, this.allEventList);

        if (icon) {
          currentDayEvents.push({
            date: event.eventDate,
            event_date: event.eventDate,
            id: event.id,
            description: event.details,
            icon: `assets/health-event/${eventType.id}.svg`,
            label: eventType.name,
            eventType: eventType.id,
          });
        }
      });

      if (currentDayEvents.length > 0) validEvents.push(currentDayEvents);
    });
    return validEvents;
  }

  isEventSelected(eventType, allEventTypes) {
    let index = allEventTypes.findIndex(o => o.id === eventType);
    return index > -1 && allEventTypes[index].isSelected
      ? `assets/health-event/${eventType}.svg`
      : null;
  }

  svgToPng(number) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 80;
    canvas.height = 80;

    ctx.font = '30px Arial';

    ctx.beginPath();
    ctx.arc(40, 40, 30, 0, 2 * Math.PI);
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.stroke();

    const textWidth = ctx.measureText(`+${number}`).width;
    ctx.fillStyle = 'black';
    ctx.fillText(`+${number}`, 30, 40 + 15);

    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  getGroupForEventHorizon(validEvents) {
    this.eventGroup = [];
    const validEventsLength = validEvents.length;

    for (let i = 0; i < validEventsLength; i++) {
      const validEvent = validEvents[i];
      if (validEvent && validEvent.length > 1) {
        let eventDisplay = '';
        let hrDisplay = `<hr style="border: solid 1px #707070; margin: 15px 0px 0px;">`;
        for (let j = 0; j < validEvent.length; j++) {
          eventDisplay =
            eventDisplay.length > 5 ? eventDisplay + hrDisplay : eventDisplay;
          eventDisplay =
            eventDisplay +
            `<span style="margin:0px; display: inline-block;"><img src="${validEvent[j].icon}" width="15px" height="15px" class="mr-1 bg-light rounded-circle">${validEvent[j].label}</span>
        <p style="margin:0px; padding: 0px;overflow: hidden; text-overflow: ellipsis;
        white-space: nowrap;">${validEvent[j].description}</p>`;
        }

        let dateDisplay = `<div style="min-width:180px;max-width:200px; padding: 0px 15px 15px; font-family: OpenSans; font-size: 12px; text-align: left;">
    <span style="color: #d2d2d2;">${validEvent[0].date}</span>`;

        let customTooltipText =
          eventDisplay.length > 2
            ? dateDisplay + hrDisplay + eventDisplay + '</>'
            : '';
        for (let j = 0; j < validEvent.length; j++) {
          let markerIcon = this.svgToPng(validEvent.length);
          this.eventGroup.push({
            text: 'Muti-Event',
            'data-description': validEvent[j].description,
            'data-image': validEvent[j].icon,
            'data-eventId': validEvent[j].id,
            values: [
              [
                Math.round(
                  new Date(validEvent[j].event_date.replace(' ', 'T')).getTime()
                ),
                Math.ceil(Math.floor(this.yMax) + this.yMax / 10),
              ],
            ],
            marker: {
              backgroundImage: markerIcon,
              backgroundScale: 0.4,
              padding: '5px 5px 5px 5px',
              borderColor: 'white',
              backgroundRepeat: 'no-repeat',
              borderRadius: '0 10px 10px 0',
              shadow: true,
              size: 12,
            },
            tooltip: {
              text: customTooltipText,
              'html-mode': true,
              zIndex: 10001,
              padding: 15,
              alpha: 0.8,
              backgroundColor: '#333',
              borderRadius: 4,
              fontColor: '#FFF',
              fontFamily: 'Open-Sans',
              fontSize: 12,
              fontWeight: 'normal',
              textAlpha: 1,
            },
            scales: 'scale-x,scale-y',
          });
        }
      } else {
        let customTooltipText = '';
        customTooltipText = `<div style="min-width:180px;max-width:200px; padding: 0px 15px 15px; font-family: OpenSans; font-size: 12px; text-align: left;">
          <span style="color: #d2d2d2;">${validEvent[0].date}</span>
          <hr style="border: solid 1px #707070; margin: 15px 0px 0px;">
          <span style="margin:0px; display: inline-block; width: 100%;"><img src="${validEvent[0].icon}" width="15px" height="15px" class="mr-1 bg-light rounded-circle">${validEvent[0].label}</span>
          <p style="margin:0px; padding: 0px;overflow: hidden; text-overflow: ellipsis;
          white-space: nowrap;">${validEvent[0].description}</p>
          </div>`;

        // set Event Group Object as per Event type
        this.eventGroup.push({
          text: validEvent[0].label,
          'data-description': validEvent[0].description,
          'data-image': validEvent[0].icon,
          'data-eventId': validEvent[0].id,
          values: [
            [
              Math.round(
                new Date(validEvent[0].event_date.replace(' ', 'T')).getTime()
              ),
              Math.ceil(Math.floor(this.yMax) + this.yMax / 10),
            ],
          ],
          marker: {
            backgroundImage: validEvent[0].icon,
            backgroundScale: 0.1,
            backgroundColor: 'white',
            padding: '5px 5px 5px 5px',
            borderColor: 'white',
            backgroundRepeat: 'no-repeat',
            borderRadius: '0 10px 10px 0',
            shadow: true,
            size: 12,
          },
          tooltip: {
            text: customTooltipText,
            class: 'customTooltip',
            'html-mode': true,
            zIndex: 10001,
            alpha: 0.8,
            backgroundColor: '#333',
            borderRadius: 4,
            fontColor: '#FFF',
            fontFamily: 'Open-Sans',
            fontSize: 12,
            fontWeight: 'normal',
            textAlpha: 1,
          },
          scales: 'scale-x,scale-y',
        });
      }
    }

    return this.eventGroup;
  }
}
