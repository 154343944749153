<ngx-ui-loader></ngx-ui-loader>
<div class="container">
  <div class="mb-5">
    <app-personal-info-form
      [canChangePatientId]="canViewLinkedAccounts"
    ></app-personal-info-form>
  </div>

  <hr *ngIf="userRole === 1001 && canViewLinkedAccounts" />

  <div class="mb-5" *ngIf="userRole === 1001 && canViewLinkedAccounts">
    <app-linked-account-information></app-linked-account-information>
  </div>
</div>
