import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { config } from '../../../config';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.css'],
})
export class IndividualComponent implements OnInit, OnChanges {
  @Input() inputData: any;
  @Input() tableData: any;
  @Output() getDateChanged: EventEmitter<any> = new EventEmitter();
  @Output() openTagModal: EventEmitter<any> = new EventEmitter();

  config = config;
  dates: any = [];
  uniqueDates: any = [];
  allRanges: any;
  testData: any = [];
  dateSelected: any;
  showRange: boolean = true;
  scoreType: string;
  rangeData: any;
  analytes: any = [];

  constructor(
    private changeRef: ChangeDetectorRef,
    private dateFilter: DatePipe
  ) {
  }

  ngOnInit() { }

  ngOnChanges(): void {

    this.dates = this.inputData?.inputDates || [];
    this.uniqueDates = this.inputData?.uniqueDates || [];
    this.rangeData = this.inputData?.newRangeData || [];
    this.analytes = this.inputData?.analytes || [];
    this.testData = this.tableData?.testData
      ? [...this.tableData.testData]
      : [];
    this.dateSelected =
      this.testData.length && this.testData[0].date
        ? this.dateFilter.transform(
          this.testData[0].date,
          config.regionConfig.dateFormatMid
        )
        : '';
    this.allRanges = this.tableData?.rangeData[0] || {};
    this.changeRef.detectChanges();
  }

  dateChanged(event) {
    this.getDateChanged.next(event);
  }

  getTimeForIndividualTest(time) {
    return this.dateFilter.transform(time.replace(' ', 'T'), 'hh:mm a');
  }

  getCount(recordIndex, cellIndex) {
    const count = this.testData[recordIndex].counts[cellIndex];

    const isValidCount =
      count !== 'NaN' && count !== 'null' && count !== '-1.0' && count !== '-1';

    return isValidCount ? count : 'N/A';
  }

  handleAddTag(record) {
    this.openTagModal.next(record);
  }

  getCellBgColor(recordIndex, cellIndex, cell) {
    const analyteIndex = this.rangeData.findIndex(
      (item) => item.analytes === cell
    );

    if (analyteIndex > -1) {
      const count = this.testData[recordIndex].counts[cellIndex];
      const validCount =
        count !== '-1' &&
        count !== '-1.0' &&
        count !== 'NAN' &&
        count !== 'null';

      if (validCount) {
        const range = this.rangeData[analyteIndex];
        const numericCount = Number(count);

        if (numericCount > range.max || numericCount < range.min) {
          return true;
        }
      }
    }

    return false;
  }
}
