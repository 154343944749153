import {
  ChangeDetectorRef,
  Component,
  VERSION,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { BarcodeFormat } from "@zxing/library";
import Swal from "sweetalert2";
import { Router, NavigationExtras } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { TestService } from "src/app/services/test.service";
declare var $: any;

@Component({
  selector: "app-qr-scanner",
  templateUrl: "./qr-scanner.component.html",
  styleUrls: ["./qr-scanner.component.css"],
})
export class QrScannerComponent implements OnInit {

  @Output() scanned: EventEmitter<any> = new EventEmitter();
  @Output() hasDevicesNewTest: EventEmitter<Boolean> = new EventEmitter();
  @Output() hasPermissionNewTest: EventEmitter<Boolean> = new EventEmitter();

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];
  value: boolean

  hasDevices: boolean = false;
  hasPermission: boolean = false;
  qrResultString: any;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  constructor(private router: Router, private changeRef: ChangeDetectorRef, private translate: TranslateService, private testService: TestService) {
  }

  ngOnInit() {
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  sentHasDevicesToNewTest(value: boolean) {
    this.hasDevicesNewTest.emit(value);
  }
  sentHasPermissionToNewTest(value: boolean) {
    this.hasPermissionNewTest.emit(value);
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    this.sentHasDevicesToNewTest(this.hasDevices);
  }

  onCodeResult(resultString: string) {
    const str = resultString.split(" ");
    this.qrResultString = str[0];

    Promise.all([
      this.testService.getTranslation('messages.scanComplete'),
      this.testService.getTranslation('messages.orderIdInvalid'),
      this.testService.getTranslation("common.okBtnText"),
      this.testService.getTranslation('messages.scanComplete'),
      this.testService.getTranslation('messages.orderIdUploadConfirm', { 'orderId': this.qrResultString }),
      this.testService.getTranslation('common.proceedBtnText')
    ]).then((res: any[]) => {
      if (this.qrResultString === undefined) {
        Swal.fire({
          type: "error",
          title: res[0],
          text: res[1],
          confirmButtonColor: "#3085d6",
          confirmButtonText: res[2],
        });
      } else {
        Swal.fire({
          type: "success",
          title: res[3],
          text: res[4],
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: res[5],
          focusCancel: true,
        }).then((result) => {
          console.log("result ::", result);
          if (result.value) {
            this.scanned.emit({ kitId: this.qrResultString });
            $("#qr-scanner").modal("hide");
          } else if (result.dismiss) {
            this.qrResultString = "";
            this.scanned.emit({ kitId: this.qrResultString });
            $("#qr-scanner").modal("hide");
          }
        });
      }
    });

  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
    this.sentHasPermissionToNewTest(this.hasPermission);
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
}
