<div class="container">
  <h3 class="text-left pb-3">
    {{ 'registerKit.pageTitle' | translate }}
  </h3>

  <div class="row m-0">
    <div class="col-12 col-md-3 p-3 text-center border">
      <img src="assets/qr-scanner.png" alt="" />
      <h6 class="mt-3">{{ 'registerKit.scanQrCode' | translate }}</h6>
      <p>{{ 'registerKit.scanInfoMsg' | translate }}</p>
      <button class="custom-scan-btn" data-toggle="modal" data-target="#qr-scanner" (click)="qrModal = true"
        [disabled]="isSaving || !userData.isProfileComplete">
        {{ 'registerKit.clickToScan' | translate }}
      </button>
    </div>

    <div class="col-12 col-md-9 p-3 border">
      <h6>
        {{ 'registerKit.infoStatement' | translate }}
      </h6>

      <div class="d-flex form-container">
        <form [formGroup]="kitForm" class="flex-grow-1">
          <mat-form-field class="example-full-width">
            <mat-label>
              {{ 'registerKit.orderNumber' | translate }}
            </mat-label>
            <input matInput type="text" id="kitCtrl" maxlength="11" minlength="11" formControlName="kitCtrl"
              placeholder="{{ 'registerKit.orderNumberValid' | translate }}" (keypress)="onKeyPress($event)"
              autocomplete="off" />
            <mat-hint align="end" style="font-size: 12px">{{
              'registerKit.orderNumberHint' | translate
              }}</mat-hint>
            <mat-error *ngIf="kitForm.controls.kitCtrl.hasError('required')">
              {{ 'registerKit.orderNumberMandatory' | translate }}
            </mat-error>
            <mat-error *ngIf="kitForm.controls.kitCtrl.hasError('modNumber')">
              {{ 'registerKit.orderNumberValid' | translate }}
            </mat-error>
          </mat-form-field>
        </form>

        <div class="cta-container">
          <button class="custom-scan-btn" id="registerKitNo" (click)="registerKitNo()" [disabled]="
              !kitForm.valid || isSaving || !userData.isProfileComplete
            ">
            {{ 'menus.registerKit' | translate }}
          </button>

          <div *ngIf="isSaving" class="d-flex align-items-center ml-3">
            <mat-spinner *ngIf="isSaving" diameter="20"></mat-spinner>
          </div>
        </div>
      </div>

      <div *ngIf="isSucceeded" class="mt-3">
        <i class="fa fa fa-check-circle mr-1" style="color: green; font-size: 16px"></i>
        {{ 'registerKit.successMsg' | translate }}
      </div>

      <div *ngIf="isFailed" class="mt-3 text-danger">
        {{ 'registerKit.failedMsg' | translate }}
      </div>

      <div *ngIf="isAlreadyExists" class="mt-3 text-danger">
        {{ 'registerKit.errorAlredyExites' | translate }}
      </div>
    </div>

    <div class="modal fade" id="qr-scanner" tabindex="-1" role="dialog" aria-labelledby="qr-scannerTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="d-flex justify-content-between w-100" *ngIf="
                !(!(hasDevices && hasPermission) || (!hasDevices && false))
              ">
              <div>
                <h5 class="modal-title" class="text-title">
                  {{ 'registerKit.scanQrCode' | translate }}
                </h5>
                <p class="text-subtitle">
                  {{ 'registerKit.scanInstruction' | translate }}
                </p>
              </div>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleQrModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="row justify-content-end w-100" *ngIf="
                !(hasDevices && hasPermission) ||
                (!hasDevices && hasPermission === null)
              ">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>

          <div class="modal-body">
            <app-qr-scanner (scanned)="qrScanned($event)" (hasDevicesNewTest)="getHasDevice($event)"
              (hasPermissionNewTest)="getHasPermission($event)" *ngIf="qrModal"></app-qr-scanner>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="data && data.length">
    <div class="col-12 p-3 m-1 d-none d-md-block d-lg-block">
      <div class="mat-elevation-z8" *ngIf="data && data.length">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="serialNumber">
            <th mat-header-cell *matHeaderCellDef>Kit Code</th>
            <td mat-cell *matCellDef="let element">
              {{ element.serialNumber }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>Registered At</th>
            <td mat-cell *matCellDef="let element">
              {{
              element.createdAt
              | date: config.regionConfig.dateTimeFormateShortV2
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: right">
              <button mat-icon-button (click)="handleSelectTestKit(element)"
                aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <div class="mat-paginator-container">
          <div class="mat-paginator-range-label">
            {{ totalRecords }} Results Found
          </div>

          <div class="mat-paginator-navigation">
            <button mat-button class="custom-page" [disabled]="pageNo === 0 || totalRecords < 7"
              (click)="goToPreviousPage()">
              <mat-icon>arrow_left</mat-icon>
              Prev
            </button>
            <button mat-button class="custom-page" [visible]="isPageShow(pageNumber)"
              *ngFor="let pageNumber of getPageNumbers()" [disabled]="pageNumber === pageNo"
              (click)="goToPage(pageNumber)">
              {{ pageNumber + 1 }}
            </button>
            <button mat-button class="custom-page" [disabled]="pageNo === getTotalPages() - 1" (click)="goToNextPage()">
              Next <mat-icon>arrow_right</mat-icon>
            </button>
          </div>
        </div>
        <mat-paginator style="visibility: hidden; display: none" [pageSize]="limit" [pageIndex]="pageNo"
          [showTotalPages]="3" [length]="totalRecords" (page)="pageEvents($event)">
        </mat-paginator>
      </div>
    </div>

    <!-- Mobile Design Table -->
    <div class="col-6 mat-paginator-container d-block d-md-none d-lg-none">
      <p style="margin-top: 12px; margin-left: 7px; font-weight: 700">
        {{ totalRecords }} Results Found
      </p>
    </div>
    <div class="col-6 mat-paginator-container d-block d-md-none d-lg-none">
      <mat-form-field appearance="outline">
        <mat-label style="font-weight: 700">Current Page No.</mat-label>
        <mat-select #select2 [(ngModel)]="pageNo" placeholder="Pages">
          <mat-option (click)="goToPage(pageNumber)" *ngFor="let pageNumber of getPageNumbers()" [value]="pageNumber">
            <span class="ng-value-label" [disabled]="pageNo === getTotalPages() - 1">{{ pageNumber + 1 }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 example-container mb-4 mat-elevation-z8 d-block d-md-none d-lg-none">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="serialNumber" sticky>
          <th mat-header-cell *matHeaderCellDef>Kit Code</th>
          <td mat-cell *matCellDef="let element">
            {{ element.serialNumber }}
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="testType">
          <th mat-header-cell *matHeaderCellDef>Test Type</th>
          <td mat-cell *matCellDef="let element">{{ element.testType }}</td>
        </ng-container> -->

        <ng-container matColumnDef="createdAt" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>createdAt</th>
          <td mat-cell *matCellDef="let element">
            {{
            element.createdAt
            | date: config.regionConfig.dateTimeFormateShortV2
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="id" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator style="visibility: hidden; display: none" [pageSize]="limit" [pageIndex]="pageNo"
      [showTotalPages]="3" [length]="totalRecords" (page)="pageEvents($event)">
    </mat-paginator>
  </div>
</div>