<div>
  <h2 class="title-text">
    <i class="fa fa-id-card mr-1"></i>
    {{ 'profilePage.personalInfo' | translate }}
  </h2>
  <hr />

  <form [formGroup]="form" class="w-100" autocomplete="off">
    <div class="form-group">
      <mat-form-field class='{{errors.patientId ? "mb-2" : ""}}'>
        <input matInput formControlName="patientId" placeholder="{{ 'profilePage.patientIdPlaceholder' | translate }}"
          autocomplete="off" (focus)='removeError("patientId")' />
        <mat-error>
          NHS number is already in use
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input matInput readonly formControlName="email"
          placeholder="{{ 'profilePage.emailPlaceholder' | translate }}" />
      </mat-form-field>

      <mat-form-field>
        <input matInput formControlName="firstName" placeholder="{{ 'profilePage.firstNamePlaceholder' | translate }}"
          autocomplete="off" />
      </mat-form-field>

      <mat-form-field>
        <input matInput formControlName="lastName" placeholder="{{ 'profilePage.lastNamePlaceholder' | translate }}"
          autocomplete="off" />
      </mat-form-field>

      <mat-form-field>
        <input matInput formControlName="dateOfBirth" placeholder="{{ 'profilePage.dobPlaceholder' | translate }}"
          autocomplete="off" [matDatepicker]="dobDatePicker" />
        <mat-datepicker-toggle matSuffix [for]="dobDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dobDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-select formControlName="gender" placeholder="{{ 'profilePage.genderPlaceholder' | translate }}">
          <mat-option *ngFor="let option of genderOptions" [value]="option.value">{{ option.label }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-group d-flex align-items-center">
      <button mat-raised-button type="button" id="demographicFormGroup-submit" class="btn personalInfo-Save"
        [disabled]="form.invalid || !form.dirty" (click)="onSubmit()">
        {{ 'common.saveBtnText' | translate }}
      </button>

      <div *ngIf="isUpdatingProfile" class="d-flex align-items-center ml-3">
        <mat-spinner *ngIf="isUpdatingProfile" diameter="20"></mat-spinner>
        <div class="ml-2">{{ 'profilePage.loadingMessage' | translate }}</div>
      </div>

      <div *ngIf="isSucceeded" class="ml-3">
        <i class="fa fa fa-check-circle mr-1" style="color: green; font-size: 16px"></i>
        {{ 'profilePage.successMessage' | translate }}
      </div>

      <div *ngIf="isFailed" class="ml-3 text-danger">
        {{ 'profilePage.errorMessage' | translate }}
      </div>
    </div>
  </form>
</div>