import { environment } from '../environments/environment';

import { registeredDomains } from './config/common/domains';

import { registeredRegion } from './config/common/regions';
import { featureFlags } from './config/common/featureFlags';

const requestedOrigin = window.location.origin;
const selectedDomain = registeredDomains.find(
  (o) => o['name'] == requestedOrigin
);
const region = selectedDomain ? selectedDomain['region'] : 'UK';
const regionConfig = registeredRegion.find((o) => o['name'] == region);
const env = selectedDomain ? selectedDomain['dev_name'] : 'DEV-UK';

let UUID = 'test';
let token = 'test';

export const updateConfigData = (data) => {
  if (data) {
    UUID = data.user.username;
    token = data.user.accessToken;
  }
};

export const config = {
  appVersion: '2.0.0',
  appName: 'Algocyte',
  baseApiv2Url: environment.baseUrl,
  UUID,
  token,
  sentryUrl: 'https://o921639.ingest.sentry.io/5868202',
  regionConfig: regionConfig.config,
  featureFlags: featureFlags,
  env: env,
  firebaseConfig: {
    apiKey: environment.firebaseConfig.apiKey,
    authDomain: environment.firebaseConfig.authDomain,
    projectId: environment.firebaseConfig.projectId,
    storageBucket: environment.firebaseConfig.storageBucket,
    messagingSenderId: environment.firebaseConfig.messagingSenderId,
    appId: environment.firebaseConfig.appId,
  },
  termsAndConditionsLink:
    '/assets/policies/ALGOCYTE T&C.html',
  privacyPolicyLink: '/assets/policies/ALGOCYTE Privacy Policy.html',
  cookiePolicyLink: '/assets/policies/ALGOCYTE Cookie Policy.html',
  instructionForUse: '/assets/Algocyte Wellness v1.0 Instructions for use',
  quickStartGuide: '/assets/Algocyte Wellness v1.0 - Quick Start Guide.pdf',
  analyteSequence: [
    {
      "RBC": "Red blood cells count"
    },
    { "HCT": "The percentage of red blood cells" },
    {
      "MCV": "Mean corpuscular volume of red blood cells"
    },
    {
      "HGB": "Amount of hemoglobin in the blood"
    },
    {
      "MCH": "Mean corpuscular hemoglobin"
    },
    {
      "MCHC": "Mean corpuscular hemoglobin concentration"
    },
    {
      "PLT": "Platelet count"
    },
    {
      "WBC": "White blood cells count"
    },
    {
      "NEUT": "Neutrophils count"
    },
    {
      "LYMPH": "Lymphocyte count"
    },
    {
      "MONO": "Monocytes count"
    },
    {
      "EOS": "Eosinophils count"
    },
    {
      "BASO": "Basophils count"
    }
  ],
  analyteSequenceArray: [
    "RBC",
    "HCT",
    "MCV",
    "HGB",
    "MCH",
    "MCHC",
    "PLT",
    "WBC",
    "NEUT",
    "LYMPH",
    "MONO",
    "EOS",
    "BASO"
  ]
};
