<div class="form-row m-0">
  <!-- Desktop Only -->

  <div class="col-12 margin-top-10 padding-5 d-none d-md-block"
    style="background-color: #f9f9f9; border: 1px solid #e3e3e3">
    <table style="width: 100%">
      <tr>
        <td>
          <div class="score-board-bullet green"></div>
        </td>
        <td class="text-left">
          <span class="font-12">Personal reference range</span>
        </td>
        <td>
          <div class="score-board-bullet yellow"></div>
        </td>
        <td class="text-left">
          <span class="font-12">Outside personal reference range</span>
        </td>
        <td>
          <div class="score-board-bullet pink"></div>
        </td>
        <td class="text-left">
          <span class="font-12">Outside population range</span>
        </td>
        <td>
          <mat-icon class="page-header-button-icon-exp" [popover]="trendsTooltip" maxWidth="500px"
            style="float: right">help_outline
          </mat-icon>

          <popover-content #trendsTooltip placement="left" [animation]="true" [closeOnClickOutside]="true">
            <table class="legend-table mt-2" style="
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
              ">
              <tbody>
                <tr>
                  <td>
                    <img src="./../../../assets/dashboard/info/2x_lb_u_l.png" height="20px" width="20px" />
                  </td>
                  <td class="donout-info-text">
                    Test Result is higher than previous result
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="./../../../assets/dashboard/info/2x_lb_d_l.png" height="20px" width="20px" />
                  </td>
                  <td class="donout-info-text">
                    Test Result is lower than previous result
                  </td>
                </tr>
                <tr>
                  <td>
                    <img src="./../../../assets/dashboard/info/2x_lb_s_l.png" height="20px" width="20px" />
                  </td>
                  <td class="donout-info-text">
                    Test Result is same as previous result
                  </td>
                </tr>
              </tbody>
            </table>
          </popover-content>
        </td>
        <td class="text-left">
          <span class="font-12">Trend Indicators</span>
        </td>
      </tr>
    </table>
  </div>

  <div *ngIf="resultSets.length" class="col-12 date-section">
    <div class="col-12 no-padding">
      <div class="row margin-bottom-20 dateSelection mx-0">
        <div class="col-12 col-md-5">
          <mat-chip-list aria-label="Year selection">
            <mat-chip disabled style="color: #212529; opacity: 1">
              Select Date:
            </mat-chip>
            <mat-chip style="cursor: pointer" class="year-selection" *ngFor="let year of yearOptions; let i = index"
              (click)="onSelectYear(year)" [ngClass]="selectedYear == year ? 'selectedYear' : ''">
              {{ year }}
            </mat-chip>
          </mat-chip-list>
        </div>

        <div class="col-12 col-md-7">
          <mat-chip-list aria-label="Month Selection">
            <mat-chip style="cursor: pointer" class="month-selection" *ngFor="let month of monthOptions; let i = index"
              (click)="onSelectMonth(month)" [ngClass]="selectedMonth == month ? 'selectedYear' : ''">{{
              displayMonth(month) }}</mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>

    <div id="date-selection-area" class="col-12">
      <ngx-slick-carousel id="Haematology-slider" class="carousel main_slider" #carousel="slick-carousel"
        [config]="sliderConfig" style="background-color: white; z-index: 1">
        <div *ngFor="let resultSet of resultSets; let i = index" ngxSlickItem class="slide"
          (click)="onResultSetClick(resultSet, i)" [ngClass]="isSelected(resultSet) ? 'slide_selected' : ''"
          style="cursor: pointer">
          <div class="row" style="padding: 8px 10px">
            <div class="col-9" style="text-align: start">
              <span class="date-selection-text" [ngClass]="
                  isSelected(resultSet) ? 'date-selection-text-selected' : ''
                ">{{ getDayOfWeek(resultSet.observationDate) }}</span>
            </div>
            <div class="col-3" style="text-align: end">
              <div style="
                  background: #e3e3e3;
                  border-radius: 4px;
                  padding: 0px;
                  float: right;
                  padding: 1px 3px;
                  align-items: center;
                ">
                <span class="date-selection-text" [ngClass]="
                    isSelected(resultSet) ? 'date-selection-text-selected' : ''
                  ">{{ resultSet.nonAdaptiveScore || '--' }}</span>
              </div>
            </div>
            <div class="col-12" style="text-align: start">
              <span style="
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #777777;
                ">
                {{ getFormattedDate(resultSet.observationDate) }}
              </span>
            </div>
            <div class="col-12" style="text-align: start">
              <span style="
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 16px;
                  color: #777777;
                ">
                {{ selectedResultSet.results?.source }}
                <span *ngIf="resultSet.testType">| {{ resultSet.testType }}</span>
              </span>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>

    <div *ngIf="!resultSets.length" class="d-flex align-items-center justify-content-center w-100"
      style="border: 1px solid #e3e3e3; min-height: 100px">
      <h5>No Health Data Found</h5>
    </div>
  </div>
</div>