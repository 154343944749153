import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { config } from '../../config';
import { DatePipe } from '@angular/common';
import { DateAdapter } from '@angular/material/core';
import Swal from 'sweetalert2';
import { ResultSetService } from 'src/app/services/resultSet/resultSet.service';
import { SelectedSubscriberService } from '../../services/selected-subscriber/selected-subscriber.service';
import { HealthEvent, User } from '../../../types';
import { MatDialog } from '@angular/material/dialog';
import { TagModalComponent } from './tag-modal/tag-modal.component';
import { HealthEventService } from '../../services//healthEvent/healthEvent.service';

declare const $: any;
const analytesList = config.analyteSequenceArray;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements AfterViewInit {
  config = config;
  isClinician = false;
  index = 0;
  originalRes: any;
  sDate: any;
  eDate: any;
  sDateStr: any;
  eDateStr: any;
  dates: any[] = [];
  originalsDate: any[] = [];
  uniqueDates: any[] = [];
  tableData: any;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  selectedProfile: any = JSON.parse(localStorage.getItem('testProfile')) || {
    analytes: [],
  };
  individualTestData: any;
  analytes: any[] = [];
  cells: any;
  filteredTags: any;
  allTags: any;
  tagsQuestions: any;
  newRangeData: any[] = [];
  _allRanges: any;
  allRanges: any;
  rangeData: any[] = [];
  customLineChartData: any;
  lineChartData: any = [];
  dataForLineChart: any[] = [];
  allResponse: any;

  points: string[] = [
    'circle',
    'cross',
    'crossRot',
    'rect',
    'rectRounded',
    'rectRot',
    'star',
    'triangle',
    'circle',
    'cross',
    'crossRot',
    'rect',
    'rectRounded',
    'rectRot',
    'star',
    'triangle',
  ];

  cellColors: string[] = [
    '#e6194B',
    '#3cb44b',
    '#9A6324',
    '#4363d8',
    '#f58231',
    '#911eb4',
    '#42d4f4',
    '#f032e6',
    '#AA00FF',
    '#800000',
    '#808000',
    '#469990',
    '#000075',
  ];

  isDateChange = false;
  showRange = true;
  selectedRow: any;
  individualTableData: any = [];
  testData: any[] = [];
  tagsFormGroup: FormGroup;
  errorMessage = false;
  noAnalyteData = false;
  selectedSubscriber: User | null;
  noHeathData: boolean;
  events: HealthEvent[] = [];

  constructor(
    private _ngxService: NgxUiLoaderService,
    private dateFilter: DatePipe,
    private router: ActivatedRoute,
    private changeRef: ChangeDetectorRef,
    private dateAdapter: DateAdapter<Date>,
    private resultSetService: ResultSetService,
    private selectedSubscriberService: SelectedSubscriberService,
    private dialog: MatDialog,
    private healthEventService: HealthEventService
  ) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngAfterViewInit(): void {
    this.initiateReportComponent();
  }

  ngOnInit() {
    this.selectedSubscriberService.getUserDataObservable().subscribe((d) => {
      if (d) {
        this.selectedSubscriber = d;
      }
    });

    this.router.paramMap.subscribe((params: ParamMap) => {
      this.initiateReportComponent();
    });
  }

  async getHealthEvents(filters) {
    if (!filters.dateRangeStart) {
      delete filters.dateRangeStart;
    }

    if (!filters.dateRangeEnd) {
      delete filters.dateRangeEnd;
    }

    this.healthEventService.getHealthEvents(filters).subscribe((events) => {
      this.events = events;
      return this.events;
    });
  }

  private getTabActive() {
    const target = this.router.snapshot.paramMap.get('target');
    switch (target) {
      case 'graph':
        this.index = 0;
        this.changeRef.detectChanges();
        break;
      case 'table':
        this.index = 1;
        this.changeRef.detectChanges();
        break;
      case 'individual':
        this.index = 2;
        this.changeRef.detectChanges();
        break;
      default:
        this.index = 0;
        this.changeRef.detectChanges();
        break;
    }
  }

  private initiateReportComponent() {
    this._ngxService.start();
    this.getTabActive();
    this.getAllReports();
    this.isClinician =
      JSON.parse(localStorage.getItem('roleId')) === 2001 ? true : false;

    $('#tagsAnnotation').on('shown.bs.modal', (e) => {
      e.stopPropagation();
      $('#singleTest').css('z-index', 1039);
    });

    $('#tagsAnnotation').on('hidden.bs.modal', (e) => {
      e.stopPropagation();
      $('#singleTest').css('z-index', 1050);
    });
  }

  getDateWithoutTime(dateString) {
    const originalDate = new Date(dateString);

    const year = originalDate.getFullYear();
    const month = originalDate.getMonth() + 1;
    const day = originalDate.getDate();

    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`;

    return formattedDate;
  }

  private async getAllReports() {
    this.events = [];

    const filters = {
      dateRangeStart: this.sDateStr
        ? this.getDateWithoutTime(this.sDateStr)
        : null,
      dateRangeEnd: this.eDateStr
        ? this.getDateWithoutTime(this.eDateStr)
        : null,
      userId: this.selectedSubscriber.id,
    };

    const events = await this.getHealthEvents(filters);

    const results = await this.resultSetService.getResultSets(filters);

    const transformededTestResults =
      this.resultSetService.transformBloodResults(results);

    if (transformededTestResults.length > 0) {
      this.errorMessage = false;
      this.noHeathData = false;
      this.selectedProfile.analytes = [];
      this.analytes = [];
      this.originalRes = [];
      this.selectedProfile.analytes = analytesList;
      this.analytes = analytesList;
      this.originalRes = transformededTestResults;
      this.rangeData = this.resultSetService.transformAnalyteRangesForReport(results);
      this._allRanges = this.resultSetService.transformReferenceRanges(results);
      this.setTabularData(this.analytes, this.rangeData, this.originalRes);

      const labels = [];
      const dataset: any = {};
      this.originalRes.forEach((test) => {
        if (test.health_id) {
          labels.push(test.date);
          if (this.originalsDate && this.originalsDate.length <= 0) {
            this.originalsDate.push(test.date);
          }
        }
      });

      this.analytes.forEach((cell: any) => {
        dataset[cell] = [];
        this.originalRes.forEach((test) => {
          if (test.health_id) {
            dataset[cell].push(Number(test[cell]));
          }
        });
      });

      if (labels.length > 0) {
        this.allResponse = JSON.parse(
          JSON.stringify({ dates: labels, cellTimeSeries: dataset })
        );
        await this.renderChart(JSON.parse(JSON.stringify(this.allResponse)));
        await this.setIndividualTestData();
      } else {
        this.noAnalyteData = true;
      }

      this._ngxService.stop();
    } else if (this.events.length > 0) {
      const dataset: any = {};
      this.analytes.forEach((cell: any) => {
        dataset[cell] = [];
      });

      this.allResponse = JSON.parse(
        JSON.stringify({ dates: [], cellTimeSeries: dataset })
      );
      this.renderChart(this.allResponse);
      this.noHeathData = true;
      this.errorMessage = false;
      await this.setIndividualTestData();
      this._ngxService.stop();
    } else {
      this.noHeathData = true;
      this.errorMessage = false;
      const dataset: any = {};
      this.analytes.forEach((cell: any) => {
        dataset[cell] = [];
      });
      this.renderChart({ dates: [], cellTimeSeries: dataset });
      this._ngxService.stop();
    }
  }

  private renderChart(resForOldChart) {
    if (this.lineChartData && this.lineChartData.length === 0) {
      this.lineChartData.push({ labels: [] });
    } else {
      this.lineChartData[0].labels = [];
    }

    resForOldChart.dates.forEach((date) => {
      this.lineChartData[0].labels.push(date.replace(' ', 'T'));
    });

    this.dates = [...this.lineChartData[0].labels];
    this.uniqueDates = this.dates.filter(
      (date, i, self) =>
        self.findIndex(
          (d) =>
            new Date(d).toLocaleDateString() ===
            new Date(date).toLocaleDateString()
        ) === i
    );

    if (!this.sDate && !this.eDate) {
      this.sDate = this.lineChartData[0].labels[0];
      this.sDateStr = new Date(this.sDate);
      this.eDate = new Date().toISOString();
      this.eDateStr = new Date();
    }

    this.dataForLineChart = [];
    this.lineChartData[0].datasets = [];

    this.selectedProfile.analytes.forEach((cell) => {
      const data = {};
      data[cell] = resForOldChart.cellTimeSeries[cell];
      this.dataForLineChart.push(data);
    });

    resForOldChart.dates = this.lineChartData[0].labels;
    resForOldChart.cellTimeSeries = this.dataForLineChart;
    this.allResponse = JSON.parse(JSON.stringify(resForOldChart));

    this.selectedProfile.analytes.forEach((val, index) => {
      const key = val;
      const replaceMinus = resForOldChart.cellTimeSeries[
        this.selectedProfile.analytes.indexOf(val)
      ][key].map((e) => (e < 0 ? 'null' : e));
      this.lineChartData[0].datasets.push({
        label: key,
        data: replaceMinus,
        fill: false,
        pointStyle: this.points[index + 1],
        borderColor: this.cellColors[index],
        backgroundColor: this.cellColors[index],
        borderWidth: 1,
        pointRadius: 5,
      });
    });

    this.setLineChartData(resForOldChart);
  }

  setStartDate(duration) {
    function setActiveTab(tabId) {
      $('#year').removeClass('active');
      $('#month').removeClass('active');
      $('#week').removeClass('active');
      $(`#${tabId}`).addClass('active');
    }

    function setStartDateAndEndDate(_duration, daysAgo) {
      const date = new Date();
      const temp = date.getDate() - daysAgo;
      date.setDate(temp);

      this.eDateStr = JSON.parse(JSON.stringify(new Date()));
      this.onChangeStartDate({ target: { value: date } });
    }

    switch (duration) {
      case 'year':
        setActiveTab('year');
        setStartDateAndEndDate.call(this, duration, 365);
        break;
      case 'month':
        setActiveTab('month');
        setStartDateAndEndDate.call(this, duration, 30);
        break;
      case 'week':
        setActiveTab('week');
        setStartDateAndEndDate.call(this, duration, 7);
        break;
      default:
        break;
    }
  }

  async onChangeStartDate(event) {
    this.isDateChange = true;
    this._ngxService.start();

    this.sDate = this.getClosestDate(event.target.value, 'start');
    this.sDateStr = JSON.parse(JSON.stringify(new Date(event.target.value)));

    await this.getAllReports();

    this._ngxService.stop();
  }

  async onChangeEndDate(event) {
    this._ngxService.start();

    this.eDate = await this.getClosestDate(event.target.value, 'end');
    this.eDateStr = JSON.parse(JSON.stringify(new Date(event.target.value)));

    const index = this.dates.lastIndexOf(this.eDate);

    if (this.allTags) {
      this.filteredTags = this.allTags.slice(
        this.dates.indexOf(JSON.parse(JSON.stringify(this.sDate))),
        index + 1
      );
    }

    await Promise.all(
      this.analytes.map(async (cell) => {
        await Promise.all(
          Object.entries(this.allResponse.cellTimeSeries).map(
            async ([key, val]) => {
              if (val[cell]) {
                this.dataForLineChart[key][cell] = val[cell].slice(
                  this.dates.indexOf(JSON.parse(JSON.stringify(this.sDate))),
                  index + 1
                );
              }
            }
          )
        );
      })
    );

    await this.getAllReports();

    this._ngxService.stop();
  }

  private getClosestDate(date, which) {
    const now = new Date(date);
    let closest;

    const getDateObject = (d) => new Date(d);

    switch (which) {
      case 'start':
        this.sDateStr = JSON.parse(JSON.stringify(now));
        this.dates.forEach((d) => {
          const currentDate = getDateObject(d);
          if (
            currentDate >= now &&
            (currentDate < getDateObject(closest) ||
              currentDate < getDateObject(closest))
          ) {
            closest = d;
          }
        });
        return closest || this.sDateStr;

      case 'end':
        this.eDateStr = JSON.parse(JSON.stringify(now));
        this.dates.forEach((d) => {
          const currentDate = getDateObject(d);
          const currentDateString = getDateObject(new Date(d).toDateString());

          if (
            currentDate <= now ||
            (currentDateString > getDateObject(closest) &&
              currentDate > getDateObject(closest))
          ) {
            closest = d;
          }
        });
        return closest || this.eDateStr;

      default:
        break;
    }
  }

  getMinMaxDate(date, option?) {
    switch (option) {
      case 'max':
        return new Date();

      case 'end':
        return new Date(this.sDateStr || date);

      case 'start':
        return new Date(this.eDateStr || date);

      default:
        if (date) {
          date = date.replace(' ', 'T');
          return new Date(date);
        }

        return new Date(date);
    }
  }

  private setLineChartData(data) {
    if (data && data.dates && this.analytes) {
      this.customLineChartData = {
        chartView: 'reportView',
        chartId: 'reportLineChart',
        analytes: [...this.analytes],
        data: { ...data },
        adaptiveScores: [],
        isReport: true,
        events: this.events,
        originalRes: { ...this.originalRes },
        isDateChange: this.isDateChange,
      };
    }
  }

  plotClicked(event) {
    this._ngxService.start();

    this.selectedRow = this.originalRes.find((item) => item === event);

    if (this.selectedRow) {
      $('#singleTest').modal({ show: true, focus: false });
    }

    this._ngxService.stop();
  }

  private setTabularData(analytes, rangeData, tableData) {
    this.tableData = { analytes, rangeData, tableData };
  }

  displayRow(element) {
    this._ngxService.start();

    const index = this.originalRes.findIndex(obj => obj.id === element.id);
    if (this.rangeData[index]) {
      let rangeData = {
        analyte_keys: [],
        reference_range: {},
        reference_unit: {}
      }
      this.rangeData[index].forEach(element => {
        rangeData.analyte_keys.push(element.analytes)
        rangeData.reference_range[element.analytes] = `${element.min}-${element.max}`
        rangeData.reference_unit[element.analytes] = element.unit
      });
      element['rangeData'] = rangeData
    }

    this.selectedRow = element;
    $('#singleTest').modal({ show: true, focus: false });
    this._ngxService.stop();
  }

  getSingleTestCount(row, cell) {
    const invalidValues = ['NaN', 'null', '-1.0', '-1'];
    return invalidValues.includes(row[cell]) ? 'N/A' : row[cell];
  }

  private getUniqueDates() {
    return this.dates.filter((date, i, self) => {
      const formattedDate = (d) => new Date(d).toLocaleDateString();
      return (
        self.findIndex((d) => formattedDate(d) === formattedDate(date)) === i
      );
    });
  }

  private setIndividualTestData() {
    this.uniqueDates = this.getUniqueDates();
    this.individualTestData = {
      inputDates: this.dates,
      uniqueDates: this.uniqueDates,
      newRangeData: this.rangeData,
      analytes: this.analytes,
    };
    this.setLatestData();
  }

  private setLatestData() {
    let latestDate;

    const dateSelected = JSON.parse(localStorage.getItem('dateSelected'));

    if (dateSelected) {
      const testWithSelectedDate = this.originalRes.find(
        (test) => test.health_id && Number(test.health_id) === dateSelected
      );

      if (testWithSelectedDate) {
        latestDate = testWithSelectedDate.date.replace(' ', 'T');
      }
    } else {
      latestDate = this.dates[this.dates.length - 1];
    }

    this.dateChanged(this.dateFilter.transform(latestDate, 'd-MMM-y'));
  }

  private dateChanged(event) {
    try {
      this._ngxService.start();

      if (navigator.userAgent.includes('Firefox')) {
        event = event.replace(/-/g, ',');
      }

      const selectedDate = this.dateFilter.transform(event, 'yyyy-MM-dd');
      const allIndexOfDate = this.dates
        .map((e, i) =>
          this.dateFilter.transform(e, 'yyyy-MM-dd') === selectedDate ? i : ''
        )
        .filter(String);

      allIndexOfDate.forEach((value) => {
        this.individualTableData = {
          allRanges: this._allRanges,
          rangeData: [],
          testData: [],
        };

        allIndexOfDate.forEach(async (val) => {
          const dataOFDate = [];

          this.allResponse.cellTimeSeries.forEach(async (data, index) => {
            if (data[this.selectedProfile.analytes[index]][val] !== 'NaN') {
              dataOFDate.push(
                this.originalRes[val][this.selectedProfile.analytes[index]] ===
                  -1
                  ? 'null'
                  : this.originalRes[val][this.selectedProfile.analytes[index]]
              );
            }
          });

          if (dataOFDate.length > 0) {
            this.individualTestData = {
              inputDates: this.dates,
              uniqueDates: this.uniqueDates,
              newRangeData: this.rangeData,
              analytes: this.selectedProfile.analytes,
            };

            if (this.rangeData[val]) {
              let format = {
                analyte_keys: [],
                reference_range: {},
                reference_unit: {}
              }

              this.rangeData[val].forEach(element => {
                format.analyte_keys.push(element.analytes)
                format.reference_range[element.analytes] = `${element.min}-${element.max}`
                format.reference_unit[element.analytes] = element.unit
              });
              this.individualTableData['rangeData'].push(format);
            }


            this.individualTableData['testData'].push({
              date: selectedDate,
              time: this.allResponse.dates[val],
              tags: this.originalRes[val].tags,
              health_id: this.originalRes[val].health_id,
              id: this.originalRes[val].id,
              counts: dataOFDate,
            });
          }
        });
      });

      this._ngxService.stop();
    } catch (error) {
      this._ngxService.stop();
    }
  }

  openTagModal(record) {
    this.selectedRow = record;

    const dialogRef = this.dialog.open(TagModalComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        resultSetId: record.id,
        tags: record.tags || [],
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        Swal.fire({
          type: 'success',
          title: 'Success!',
          text: 'The tags saved successfully',
          confirmButtonText: 'OK',
        });

        await this.getAllReports();
        this.selectedRow =
          this.originalRes.find((x) => x.id === record.id) || record;
      }
    });
  }

  addInGivenAns($event, question) {
    if (question.multiple === 'true') question.givenAns.push($event.aid);
  }

  clearItem(item, question) {
    question.multiple === 'true'
      ? question.givenAns.splice(question.givenAns.indexOf(item.aid), 1)
      : (question.givenAns = []);
  }
}
