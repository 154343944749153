import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResultSetResponse, getResultSetInput, Tag } from './types';
import { config } from '../../config';
import { BloodResult } from 'src/app/services/resultSet/types';
import { Observable } from 'rxjs';

interface LegacyTestResult {
  id: number;
  date: string;
  tags: Tag[];
  [key: string]: string | string[] | number | Tag[];
}
interface AnalyteRange {
  label: string;
  analytes: string;
  min: number;
  max: number;
  unit: string;
}

export const analytesList = config.analyteSequenceArray;

export const analyteAliases = {
  RBC: ['RBC'],
  HCT: ['HCT'],
  MCV: ['MCV'],
  MCHC: ['MCHC'],
  HGB: ['HGB', 'HBGL'],
  MCH: ['MCH'],
  PLT: ['PLT', 'PLTS'],
  WBC: ['WBC'],
  NEUT: ['NEUT'],
  LYMPH: ['LYMPH', 'LYMP'],
  MONO: ['MONO'],
  EOS: ['EOS', 'EOSI'],
  BASO: ['BASO'],
};

// Create a reverse mapping from alias to primary key
export const aliasToPrimaryKey = {};

Object.entries(analyteAliases).forEach(([key, aliases]) => {
  aliases.forEach((alias) => {
    aliasToPrimaryKey[alias] = key;
  });
});

export const primaryKeyToAlias = {};

Object.entries(analyteAliases).forEach(([key, aliases]) => {
  // Store only the aliases, not including the primary key itself (if desired)
  primaryKeyToAlias[key] = aliases.filter((alias) => alias !== key);
});

@Injectable({
  providedIn: 'root',
})
export class ResultSetService {
  private readonly API_URL = `${config.baseApiv2Url}/result-sets`;

  constructor(private http: HttpClient) { }

  getResultSets(filters: getResultSetInput): Promise<ResultSetResponse[]> {
    let params = new HttpParams();

    if (filters.dateRangeStart) {
      params = params.append('dateRangeStart', filters.dateRangeStart);
    }
    if (filters.dateRangeEnd) {
      params = params.append('dateRangeEnd', filters.dateRangeEnd);
    }
    if (filters.userId) {
      params = params.append('userId', filters.userId);
    }

    return this.http
      .get<ResultSetResponse[]>(this.API_URL, { params })
      .toPromise();
  }

  getResultSets$(filters: getResultSetInput): Observable<ResultSetResponse[]> {
    let params = new HttpParams();

    if (filters.dateRangeStart) {
      params = params.append('dateRangeStart', filters.dateRangeStart);
    }
    if (filters.dateRangeEnd) {
      params = params.append('dateRangeEnd', filters.dateRangeEnd);
    }
    if (filters.userId) {
      params = params.append('userId', filters.userId);
    }

    return this.http.get<ResultSetResponse[]>(this.API_URL, { params });
  }

  transformAnalyteRanges = (data: any[]): AnalyteRange[] => {
    const ranges: AnalyteRange[] = [];

    const firstEntry = data[0];
    if (firstEntry && firstEntry.results && firstEntry.results.analyteResults) {
      firstEntry.results.analyteResults.forEach((analyteResult) => {
        const { analyteKey, unit, referenceRange } = analyteResult;
        const primaryAnalyteKey = aliasToPrimaryKey[analyteKey] || analyteKey;
        const [min, max] = referenceRange
          .split('-')
          .map((val) => parseFloat(val.trim()));

        ranges.push({
          label: `${primaryAnalyteKey}: ${min} - ${max}`,
          analytes: primaryAnalyteKey,
          min: min,
          max: max,
          unit: unit,
        });
      });
    }

    return ranges;
  };

  transformAnalyteRangesForReport = (data: any[]): AnalyteRange[] => {
    const allResultsRanges = []
    data.forEach((firstEntry) => {
      const ranges: AnalyteRange[] = [];
      if (firstEntry && firstEntry.results && firstEntry.results.analyteResults) {
        firstEntry.results.analyteResults.forEach((analyteResult) => {
          const { analyteKey, unit, referenceRange } = analyteResult;
          const primaryAnalyteKey = aliasToPrimaryKey[analyteKey] || analyteKey;
          const [min, max] = referenceRange
            .split('-')
            .map((val) => parseFloat(val.trim()));

          ranges.push({
            label: `${primaryAnalyteKey}: ${min} - ${max}`,
            analytes: primaryAnalyteKey,
            min: min,
            max: max,
            unit: unit,
          });
        });
      }
      allResultsRanges.push(ranges);
    })

    return allResultsRanges;
  };


  transformReferenceRanges(dataArray: any) {
    const transformedData = {
      analyte_keys: [],
      reference_range: {},
      reference_unit: {},
    };

    dataArray.forEach((data) => {
      if (data.results && Array.isArray(data.results.analyteResults)) {
        data.results.analyteResults.forEach((analyte) => {
          const primaryAnalyteKey = aliasToPrimaryKey[analyte.analyteKey];

          if (
            primaryAnalyteKey &&
            !transformedData.analyte_keys.includes(primaryAnalyteKey)
          ) {
            transformedData.analyte_keys.push(primaryAnalyteKey);
            transformedData.reference_range[primaryAnalyteKey] =
              analyte.referenceRange.replace(/\s+/g, '');
            transformedData.reference_unit[primaryAnalyteKey] = analyte.unit;
          }
        });
      }
    });

    transformedData.analyte_keys.sort();

    return transformedData;
  }

  transformBloodResults(newApiResponse: BloodResult[]): LegacyTestResult[] {
    const sortedApiResponse = newApiResponse.sort(
      (a, b) =>
        new Date(a.results.observationDate).getTime() -
        new Date(b.results.observationDate).getTime()
    );

    return sortedApiResponse.map((bloodResult) => {
      const transformed: LegacyTestResult = {
        id: bloodResult.id,
        date: bloodResult.results.observationDate,
        tags: bloodResult.tags,
        health_id: 1,
      };

      bloodResult.results.analyteResults.forEach((analyte) => {
        const primaryAnalyteKey = aliasToPrimaryKey[analyte.analyteKey];

        if (primaryAnalyteKey) {
          transformed[primaryAnalyteKey] = analyte.analyteValue;
        }
      });

      return transformed;
    });
  }
}
