<div class="col-12 px-4 py-4 pb-3" [hidden]="showLineChart">
  <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
      width: '100%',
      height: '350px',
      'border-radius': '10px',
      margin: '5px 0px',
      float: 'left'
    }">
  </ngx-skeleton-loader>
</div>

<div class="col-12 no-padding" [hidden]="!showLineChart">
  <div class="row no-margin" [hidden]="!showLineChart" *ngIf="!isReport && allEventList && allEventList.length"
    style="background-color: #f9f9f9">
    <div class="col-md-9 col-12 score-over-time-events no-padding">
      <div class="d-flex" style="background-color: #f9f9f9; padding: 6px 10px 6px 10px">
        <div *ngFor="let event of allEventList; let i = index" class="legend-wrapper" (click)="selectEventType(i)"
          [ngClass]="
            !getEventBorderColor(i)
              ? 'legend-wrapper-un-selected'
              : 'legend-wrapper-selected'
          " style="padding: 6px 10px 6px 10px !important" [matTooltip]="event.label">
          <div class="legend-text">
            <img src="{{ event.icon }}" width="20px" height="20px" />
          </div>
        </div>
      </div>
    </div>

    <!-- For the laptop and large device -->
    <div class="col-md-3 d-none d-md-block" style="padding: 0px">
      <div class="d-flex" style="
          justify-content: flex-end;
          background-color: #f9f9f9;
          padding: 6px 10px 6px 10px;
          flex-wrap: wrap;
        ">
        <div class="legend-wrapper" *ngIf="!isAllSelected" [ngClass]="
            !getEventBorderColor(i, 'Select All')
              ? 'legend-wrapper-un-selected'
              : 'legend-wrapper-selected'
          " (click)="selectEventType(null, 'all')" style="padding: 6px 10px 6px 10px !important"
          matTooltip="Select All">
          <div class="legend-text" style="font-size: 13px">Select All</div>
        </div>
        <div class="legend-wrapper" *ngIf="!isAllUnSelected" [ngClass]="
            !getEventBorderColor(null, 'Unselect All')
              ? 'legend-wrapper-un-selected'
              : 'legend-wrapper-selected'
          " (click)="selectEventType(null, 'zero')" style="padding: 6px 10px 6px 10px !important"
          matTooltip="Unselect All">
          <div class="legend-text" style="font-size: 13px">Unselect All</div>
        </div>
      </div>
    </div>
    <!-- For the mobile and tablet device -->
    <div class="col-12 d-block d-md-none d-lg-none" style="padding: 0px">
      <div class="d-flex" style="
          justify-content: center;
          background-color: #f9f9f9;
          padding: 6px 10px 6px 10px;
          flex-wrap: wrap;
        ">
        <div class="legend-wrapper" *ngIf="!isAllSelected" [ngClass]="
            !getEventBorderColor(i, 'Select All')
              ? 'legend-wrapper-un-selected'
              : 'legend-wrapper-selected'
          " (click)="selectEventType(null, 'all')" style="padding: 6px 10px 6px 10px !important"
          matTooltip="Select All">
          <div class="legend-text" style="font-size: 13px">Select All</div>
        </div>
        <div class="legend-wrapper" *ngIf="!isAllUnSelected" [ngClass]="
            !getEventBorderColor(null, 'Unselect All')
              ? 'legend-wrapper-un-selected'
              : 'legend-wrapper-selected'
          " (click)="selectEventType(null, 'zero')" style="padding: 6px 10px 6px 10px !important"
          matTooltip="Unselect All">
          <div class="legend-text" style="font-size: 13px">Unselect All</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" [hidden]="!showLineChart" *ngIf="!isReport">
    <div class="col-12 score-over-time">
      <div id="{{ chartId }}" class="chart--container"></div>
    </div>
  </div>

  <div class="w-100 overflow-hidden" [hidden]="!showLineChart" *ngIf="isReport">
    <div class="row legend-bar" *ngIf="selectedAnalytes && selectedAnalytes.length">
      <div class="col-md-10" style="padding-left: 50px">
        <mat-chip-list class="my-chip">
          <mat-chip disableRipple class="legend-chip" id="{{ analyte.name }}-trends-selection"
            *ngFor="let analyte of selectedAnalytes; let i = index" (click)="selectAnalyte(i)"
            [ngClass]="{ 'legend-selected': analyte.selectd === true }">
            <span style="
                font-size: OpenSans-Semibold;
                font-size: 12px;
                line-height: 18px;
                color: #333333;
              ">
              <!-- Square -->
              <svg *ngIf="analyte.marker == 'square'"
                class="margin-right-2 margin-left-2 margin-bottom-2 svg-inline--fa fa-square fa-w-14"
                [ngStyle]="{ fill: analyte.color }" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="12px"
                height="12px">
                <path
                  d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z">
                </path>
              </svg>

              <!-- Circle -->
              <svg *ngIf="analyte.marker == 'circle'"
                class="margin-right-2 margin-left-2 margin-bottom-2 svg-inline--fa fa-circle fa-w-16"
                [ngStyle]="{ fill: analyte.color }" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="12px"
                height="12px">
                <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
              </svg>

              <!-- diamond -->
              <svg *ngIf="analyte.marker == 'diamond'" class="margin-right-2 margin-left-2 margin-bottom-2"
                [ngStyle]="{ fill: analyte.color }" version="1.0" xmlns="http://www.w3.org/2000/svg" width="14px"
                height="14px" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M841 1617 c-84 -155 -161 -289 -171 -297 -9 -8 -142 -82 -294 -165
                -152 -82 -276 -152 -276 -155 0 -3 124 -72 276 -155 152 -82 285 -157 294
                -165 10 -8 87 -142 171 -297 85 -156 156 -283 159 -283 3 0 75 130 161 288
                l155 287 120 67 c65 37 197 109 292 160 94 51 172 95 172 98 0 3 -62 38 -138
                79 -75 41 -206 112 -290 158 l-152 85 -60 106 c-33 59 -103 189 -157 289 -54
                101 -100 183 -103 183 -3 0 -74 -127 -159 -283z" />
                </g>
              </svg>

              <!-- triangle -->
              <svg *ngIf="analyte.marker == 'triangle'" class="margin-right-2 margin-left-2 margin-bottom-2"
                [ngStyle]="{ fill: analyte.color }" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                viewBox="0 0 24 24">
                <path d="M24 22h-24l12-20z" />
              </svg>

              <!-- star 5 -->
              <svg *ngIf="analyte.marker == 'star5'" [ngStyle]="{ fill: analyte.color }"
                class="margin-right-2 margin-left-2 margin-bottom-2" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="12px" height="12px" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M851 1615 c-126 -257 -148 -295 -170 -299 -14 -3 -156 -24 -316 -46
                            -160 -23 -295 -43 -302 -45 -9 -3 289 -307 416 -423 l45 -40 -54 -314 c-29
                            -172 -55 -319 -58 -327 -3 -8 1 -12 9 -10 8 2 142 71 297 152 l282 148 132
                            -69 c73 -38 204 -106 291 -152 87 -45 160 -81 162 -79 2 2 -10 78 -26 169 -15
                            91 -40 237 -55 324 l-26 160 51 46 c71 64 422 412 419 414 -2 1 -124 19 -273
                            40 -148 21 -295 43 -326 48 l-56 10 -144 294 c-79 162 -146 294 -149 293 -3 0
                            -70 -132 -149 -294z" />
                </g>
              </svg>

              <!-- star 6 -->
              <svg *ngIf="analyte.marker == 'star6'" class="margin-right-2 margin-left-2 margin-bottom-2 rotate-28"
                [ngStyle]="{ fill: analyte.color }" version="1.0" xmlns="http://www.w3.org/2000/svg" width="12px"
                height="12px" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M885 1656 c-59 -135 -113 -250 -119 -254 -6 -5 -62 -3 -126 4 -63 7
                -182 20 -263 29 -82 9 -151 15 -153 12 -3 -3 67 -103 155 -223 89 -121 161
                -221 161 -224 0 -5 -67 -97 -238 -328 -46 -63 -81 -116 -79 -119 3 -3 72 3
                154 12 81 9 200 22 263 29 64 7 120 9 126 4 6 -4 60 -119 119 -254 60 -134
                111 -241 115 -237 4 4 18 35 32 68 87 202 182 412 192 421 8 8 80 4 275 -18
                145 -16 266 -27 268 -24 3 3 -48 77 -114 166 -65 89 -137 188 -161 221 l-43
                59 43 59 c24 33 96 132 161 221 66 89 117 163 114 166 -2 3 -123 -8 -268 -24
                -195 -22 -267 -26 -276 -18 -9 9 -90 188 -191 421 -14 33 -28 64 -32 68 -4 4
                -55 -103 -115 -237z" />
                </g>
              </svg>

              <!-- star 7 -->
              <svg *ngIf="analyte.marker == 'star7'" class="margin-right-2 margin-left-2 margin-bottom-2"
                [ngStyle]="{ fill: analyte.color }" version="1.0" xmlns="http://www.w3.org/2000/svg" width="12px"
                height="12px" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M892 1649 l-100 -254 -34 2 c-18 1 -136 34 -262 73 -125 39 -230 69
                    -233 66 -3 -3 53 -108 126 -234 72 -126 131 -234 131 -239 0 -9 -63 -54 -382
                    -272 -36 -25 -65 -47 -63 -49 3 -3 130 -24 355 -57 96 -15 179 -30 183 -34 4
                    -4 -1 -128 -13 -276 -11 -147 -19 -269 -17 -271 2 -2 93 80 203 181 109 101
                    202 184 207 185 4 0 98 -84 208 -186 110 -102 202 -184 203 -183 2 2 -5 105
                    -15 229 -10 124 -18 248 -18 276 l-1 50 248 38 c343 51 312 41 249 83 -116 80
                    -402 277 -406 279 -2 2 58 110 133 240 75 130 134 239 132 241 -2 2 -115 -30
                    -250 -71 -135 -41 -253 -76 -262 -78 -11 -2 -36 49 -100 212 -47 118 -93 235
                    -103 259 l-18 43 -101 -253z" />
                </g>
              </svg>

              <!-- star 8 -->
              <svg *ngIf="analyte.marker == 'star8'" class="margin-right-2 margin-left-2 margin-bottom-2"
                [ngStyle]="{ fill: analyte.color }" version="1.0" xmlns="http://www.w3.org/2000/svg" width="12px"
                height="12px" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M915 1665 c-43 -124 -82 -230 -85 -235 -4 -6 -102 35 -229 96 -123
                    58 -225 104 -227 101 -2 -2 43 -104 101 -227 59 -125 102 -226 96 -229 -5 -3
                    -111 -42 -235 -86 -124 -44 -226 -82 -226 -85 0 -3 102 -41 226 -85 124 -44
                    230 -83 235 -86 6 -3 -37 -104 -96 -229 -58 -123 -104 -225 -101 -227 2 -3
                    104 43 227 101 127 61 225 102 229 96 3 -5 42 -111 85 -235 44 -124 83 -225
                    86 -225 3 0 43 106 89 235 45 129 85 235 87 235 2 0 103 -47 225 -105 121 -58
                    223 -103 225 -101 3 2 -43 104 -101 227 -58 122 -106 223 -106 224 0 1 106 40
                    235 86 129 46 235 86 235 89 0 3 -106 43 -235 89 -129 46 -235 85 -235 86 0 1
                    34 74 76 161 42 88 91 192 109 231 l34 73 -37 -19 c-52 -27 -422 -201 -426
                    -201 -1 0 -41 106 -86 235 -46 129 -86 235 -89 235 -3 0 -42 -101 -86 -225z" />
                </g>
              </svg>

              <!-- rpoly5 -->
              <svg *ngIf="analyte.marker == 'rpoly5'" class="margin-right-2 margin-left-2 margin-bottom-2"
                [ngStyle]="{ fill: analyte.color }" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60"
                style="enable-background: new 0 0 60 60" xml:space="preserve" width="12px" height="12px">
                <path d="M59.662,26.042L30.701,0.458c-0.377-0.332-0.94-0.334-1.319-0.004L0.343,25.79c-0.306,0.267-0.42,0.692-0.289,1.076
                    l11,32.249c0.138,0.405,0.519,0.677,0.946,0.677h35.954c0.427,0,0.806-0.271,0.945-0.674l11.046-32
                    C60.077,26.735,59.966,26.311,59.662,26.042z" />
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>

              <!-- gear 5 -->
              <svg *ngIf="analyte.marker == 'gear5'" [ngStyle]="{ fill: analyte.color }"
                class="margin-right-2 margin-left-2 margin-bottom-2 rotate-180" version="1.0"
                xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="0 0 200.000000 200.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M851 1615 c-126 -257 -148 -295 -170 -299 -14 -3 -156 -24 -316 -46
                                              -160 -23 -295 -43 -302 -45 -9 -3 289 -307 416 -423 l45 -40 -54 -314 c-29
                                              -172 -55 -319 -58 -327 -3 -8 1 -12 9 -10 8 2 142 71 297 152 l282 148 132
                                              -69 c73 -38 204 -106 291 -152 87 -45 160 -81 162 -79 2 2 -10 78 -26 169 -15
                                              91 -40 237 -55 324 l-26 160 51 46 c71 64 422 412 419 414 -2 1 -124 19 -273
                                              40 -148 21 -295 43 -326 48 l-56 10 -144 294 c-79 162 -146 294 -149 293 -3 0
                                              -70 -132 -149 -294z" />
                </g>
              </svg>

              <!-- gear 6 -->
              <svg *ngIf="analyte.marker == 'gear6'" class="margin-right-2 margin-left-2 margin-bottom-2 rotate-208"
                [ngStyle]="{ fill: analyte.color }" version="1.0" xmlns="http://www.w3.org/2000/svg" width="12px"
                height="12px" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M885 1656 c-59 -135 -113 -250 -119 -254 -6 -5 -62 -3 -126 4 -63 7
                                  -182 20 -263 29 -82 9 -151 15 -153 12 -3 -3 67 -103 155 -223 89 -121 161
                                  -221 161 -224 0 -5 -67 -97 -238 -328 -46 -63 -81 -116 -79 -119 3 -3 72 3
                                  154 12 81 9 200 22 263 29 64 7 120 9 126 4 6 -4 60 -119 119 -254 60 -134
                                  111 -241 115 -237 4 4 18 35 32 68 87 202 182 412 192 421 8 8 80 4 275 -18
                                  145 -16 266 -27 268 -24 3 3 -48 77 -114 166 -65 89 -137 188 -161 221 l-43
                                  59 43 59 c24 33 96 132 161 221 66 89 117 163 114 166 -2 3 -123 -8 -268 -24
                                  -195 -22 -267 -26 -276 -18 -9 9 -90 188 -191 421 -14 33 -28 64 -32 68 -4 4
                                  -55 -103 -115 -237z" />
                </g>
              </svg>

              <!-- gear 7 -->
              <svg *ngIf="analyte.marker == 'gear7'" class="margin-right-2 margin-left-2 margin-bottom-2"
                [ngStyle]="{ fill: analyte.color }" version="1.0" xmlns="http://www.w3.org/2000/svg" width="12px"
                height="12px" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M892 1649 l-100 -254 -34 2 c-18 1 -136 34 -262 73 -125 39 -230 69
                                      -233 66 -3 -3 53 -108 126 -234 72 -126 131 -234 131 -239 0 -9 -63 -54 -382
                                      -272 -36 -25 -65 -47 -63 -49 3 -3 130 -24 355 -57 96 -15 179 -30 183 -34 4
                                      -4 -1 -128 -13 -276 -11 -147 -19 -269 -17 -271 2 -2 93 80 203 181 109 101
                                      202 184 207 185 4 0 98 -84 208 -186 110 -102 202 -184 203 -183 2 2 -5 105
                                      -15 229 -10 124 -18 248 -18 276 l-1 50 248 38 c343 51 312 41 249 83 -116 80
                                      -402 277 -406 279 -2 2 58 110 133 240 75 130 134 239 132 241 -2 2 -115 -30
                                      -250 -71 -135 -41 -253 -76 -262 -78 -11 -2 -36 49 -100 212 -47 118 -93 235
                                      -103 259 l-18 43 -101 -253z" />
                </g>
              </svg>

              <!-- gear 8 -->
              <svg *ngIf="analyte.marker == 'gear8'" class="margin-right-2 margin-left-2 margin-bottom-2"
                [ngStyle]="{ fill: analyte.color }" version="1.0" xmlns="http://www.w3.org/2000/svg" width="12px"
                height="12px" viewBox="0 0 200.000000 200.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" stroke="none">
                  <path d="M915 1665 c-43 -124 -82 -230 -85 -235 -4 -6 -102 35 -229 96 -123
                                      58 -225 104 -227 101 -2 -2 43 -104 101 -227 59 -125 102 -226 96 -229 -5 -3
                                      -111 -42 -235 -86 -124 -44 -226 -82 -226 -85 0 -3 102 -41 226 -85 124 -44
                                      230 -83 235 -86 6 -3 -37 -104 -96 -229 -58 -123 -104 -225 -101 -227 2 -3
                                      104 43 227 101 127 61 225 102 229 96 3 -5 42 -111 85 -235 44 -124 83 -225
                                      86 -225 3 0 43 106 89 235 45 129 85 235 87 235 2 0 103 -47 225 -105 121 -58
                                      223 -103 225 -101 3 2 -43 104 -101 227 -58 122 -106 223 -106 224 0 1 106 40
                                      235 86 129 46 235 86 235 89 0 3 -106 43 -235 89 -129 46 -235 85 -235 86 0 1
                                      34 74 76 161 42 88 91 192 109 231 l34 73 -37 -19 c-52 -27 -422 -201 -426
                                      -201 -1 0 -41 106 -86 235 -46 129 -86 235 -89 235 -3 0 -42 -101 -86 -225z" />
                </g>
              </svg>

              {{ analyte.name }}
            </span>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div class="col-md-2 text-center">
        <p class="deselectAll" id="deselectAll" (click)="handleSelect(true)" [ngClass]="{
            deselectAll: deselectAllLineChart,
            selectedOne: !deselectAllLineChart
          }">
          {{ 'reports.deselectAll' | translate }}
        </p>

        <p class="selectedOne" id="selectAll" (click)="handleSelect(false)" [ngClass]="{
            selectedOne: !selectAllLineChart,
            deselectAll: selectAllLineChart
          }">
          {{ 'reports.selectAll' | translate }}
        </p>
      </div>
    </div>

    <hr class="m-0" />

    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="row" *ngIf="allEventList && allEventList.length">
          <div class="col-md-9" style="padding-right: 0px">
            <div class="d-flex" style="
                background-color: #f9f9f9;
                padding: 6px 10px 6px 30px;
                flex-wrap: wrap;
              ">
              <div id="{{ event.label }}-trends-selection" *ngFor="let event of allEventList; let i = index"
                class="legend-wrapper" (click)="selectEventType(i)" [ngClass]="
                  event.isSelected
                    ? 'legend-wrapper-selected'
                    : 'legend-wrapper-un-selected'
                " style="padding: 6px 10px 6px 10px !important">
                <div class="legend-text" [matTooltip]="event.label">
                  <img src="{{ event.icon }}" width="20px" height="20px" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3" style="padding-left: 0px">
            <div class="d-flex" style="
                justify-content: flex-end;
                background-color: #f9f9f9;
                padding: 6px 10px 6px 10px;
                flex-wrap: wrap;
              ">
              <div class="legend-wrapper" *ngIf="!isAllSelected" [ngClass]="
                  !getEventBorderColor(i, 'Select All')
                    ? 'legend-wrapper-un-selected'
                    : 'legend-wrapper-selected'
                " (click)="selectEventType(null, 'all')" style="padding: 6px 10px 6px 10px !important"
                matTooltip="Select All">
                <div class="legend-text" style="font-size: 13px">
                  Select All
                </div>
              </div>

              <div class="legend-wrapper" *ngIf="!isAllUnSelected" [ngClass]="
                  !getEventBorderColor(null, 'Unselect All')
                    ? 'legend-wrapper-un-selected'
                    : 'legend-wrapper-selected'
                " (click)="selectEventType(null, 'zero')" style="padding: 6px 10px 6px 10px !important"
                matTooltip="Unselect All">
                <div class="legend-text" style="font-size: 13px">
                  Unselect All
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div id="{{ chartId }}" class="chart--container"></div>
        </div>
      </div>
    </div>
  </div>
</div>