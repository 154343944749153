<ngx-ui-loader></ngx-ui-loader>
<div class="container">
  <div class="row">
    <div class="col-12">
      <h2 class="text-center">{{ 'reports.pageTitle' | translate }}</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button id="year" [disabled]="originalRes && originalRes.length === 0" class="col-4" mat-raised-button
        (click)="setStartDate('year')">
        {{ 'reports.yearAgo' | translate }}
      </button>
      <button id="month" [disabled]="originalRes && originalRes.length === 0" class="col-4" mat-raised-button
        (click)="setStartDate('month')">
        {{ 'reports.monthAgo' | translate }}
      </button>
      <button id="week" [disabled]="originalRes && originalRes.length === 0" class="col-4" mat-raised-button
        (click)="setStartDate('week')">
        {{ 'reports.weekAgo' | translate }}
      </button>
      <mat-form-field class="form-group col-12 col-md-6 mt-3">
        <input matInput [disabled]="originalRes && originalRes.length === 0" autocomplete="off" id="sDate" name="sDate"
          (dateChange)="onChangeStartDate($event)" [max]="getMinMaxDate(null, 'start')"
          [min]="getMinMaxDate(originalsDate[0])" [(ngModel)]="sDateStr"
          placeholder="{{ 'reports.chooseStartDate' | translate }}" [matDatepicker]="myDatepickerStart" readonly />
        <mat-datepicker-toggle #startDate matSuffix [for]="myDatepickerStart"></mat-datepicker-toggle>
        <mat-datepicker #myDatepickerStart></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="form-group col-12 col-md-6 mt-3">
        <input matInput [disabled]="originalRes && originalRes.length === 0" autocomplete="off" id="eDate" name="eDate"
          (dateChange)="onChangeEndDate($event)" [min]="getMinMaxDate(dates[0], 'end')"
          [max]="getMinMaxDate(null, 'max')" [(ngModel)]="eDateStr"
          placeholder="{{ 'reports.chooseEndDate' | translate }}" [matDatepicker]="myDatepickerEnd" readonly />
        <mat-datepicker-toggle #endDate matSuffix [for]="myDatepickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #myDatepickerEnd></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-tab-group mat-align-tabs="center" mat-stretch-tabs [(selectedIndex)]="index">
        <mat-tab class="w-100" label="Trends" id="trends-tab-web">
          <ng-template matTabContent>
            <app-line class="col-12" style="padding: 0px" *ngIf="customLineChartData && !errorMessage && !noAnalyteData"
              [lineData]="customLineChartData" (clickedTest)="plotClicked($event)">
            </app-line>
            <div class="col-md-12 mt-5" *ngIf="errorMessage" style="margin-top: 10%">
              <h2 class="text-center">
                {{ 'reports.noHealthDataPresent' | translate }}
              </h2>
            </div>
            <div class="col-md-12 mt-5" *ngIf="noAnalyteData">
              <h2 class="text-center">
                {{ 'reports.noAnalyteDataPresent' | translate }}
              </h2>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab class="w-100" label="Tabular" id="tabular-tab-web">
          <ng-template matTabContent>
            <app-tabular *ngIf="tableData && !noHeathData" [data]="tableData" [originalRes]="originalRes"
              (openSingleTestModal)="displayRow($event)">
            </app-tabular>
            <div class="col-md-12" *ngIf="noHeathData" style="margin-top: 10%">
              <h2 class="text-center">
                {{ 'reports.noHealthDataPresent' | translate }}
              </h2>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab class="w-100" label="Individual" id="individual-tab-web">
          <ng-template matTabContent>
            <app-individual *ngIf="
                (individualTestData || individualTableData) &&
                !noHeathData &&
                !noAnalyteData
              " [inputData]="individualTestData" [tableData]="individualTableData"
              (getDateChanged)="dateChanged($event)" (openTagModal)="openTagModal($event)">
            </app-individual>
            <div class="col-md-12" *ngIf="noHeathData" style="margin-top: 10%">
              <h2 class="text-center">
                {{ 'reports.noHealthDataPresent' | translate }}
              </h2>
            </div>
            <div class="col-md-12" *ngIf="noAnalyteData">
              <h2 class="text-center">
                {{ 'reports.noAnalyteDataPresent' | translate }}
              </h2>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <div class="modal fade" id="singleTest" tabindex="-1" role="dialog" aria-labelledby="indvidualtTest"
    aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content" *ngIf="selectedRow">
        <div class="modal-header">
          <h5 class="modal-title" id="indvidualtTest" *ngIf="selectedRow && selectedRow.health_id">
            {{ 'reports.individualBloodTest' | translate }}
          </h5>
          <h5 class="modal-title" id="indvidualtTest" *ngIf="!(selectedRow && selectedRow.health_id)">
            {{ 'reports.recordedEvent' | translate }}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body" *ngIf="
            selectedRow && selectedRow.health_id && _allRanges;
            else eventTemplate
          ">
          <div class="full table table-responsive">
            <table class="w-100">
              <thead>
                <tr>
                  <th>{{ 'reports.analyte' | translate }}</th>
                  <th>
                    {{
                    selectedRow.date
                    | date: config.regionConfig.dateTimeFormateMid
                    }}
                  </th>
                  <th>{{ 'reports.referenceRange' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let cell of analytes;">
                  <td>{{ cell }}</td>
                  <td>{{ getSingleTestCount(selectedRow, cell) || 'N/A' }}</td>
                  <td *ngIf="showRange">
                    {{ selectedRow.rangeData.reference_range[cell]
                    }} {{ selectedRow.rangeData.reference_unit[cell] }}
                  </td>
                </tr>
                <tr>
                  <td>{{ 'reports.tag' | translate }}</td>
                  <td>
                    <ul *ngIf="
                        selectedRow.tags && selectedRow.tags.length;
                        else elseTemplate
                      ">
                      <li *ngFor="let tag of selectedRow.tags; let i = index">
                        {{ tag.text }}
                      </li>
                    </ul>
                    <ng-template #elseTemplate>
                      <h6>{{ 'reports.noTagsErrorMessage' | translate }}</h6>
                    </ng-template>
                    <div>
                      <button type="button" class="btn btn-success" style="width: -webkit-fill-available"
                        *ngIf="selectedRow.tags && selectedRow.tags.length" (click)="openTagModal(selectedRow)">
                        {{ 'reports.editTag' | translate }}
                      </button>
                      <button type="button" class="btn btn-primary" style="width: -webkit-fill-available" *ngIf="
                          selectedRow.tags === '' ||
                          selectedRow.tags.length <= 0
                        " (click)="openTagModal(selectedRow)">
                        {{ 'reports.addTag' | translate }}
                      </button>
                    </div>
                  </td>
                  <td>{{ 'common.notApplicableText' | translate }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <ng-template #eventTemplate>
          <div class="modal-body">
            <h5>
              {{
              selectedRow.date | date: config.regionConfig.dateTimeFormateMid
              }}
            </h5>
            <ul *ngIf="selectedRow.tags && selectedRow.tags.length">
              <li *ngFor="let tag of selectedRow.tags; let i = index">
                {{ tag }}
              </li>
            </ul>
          </div>
        </ng-template>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ 'common.closeBtnText' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="tagsAnnotation" tabindex="-1" role="dialog" aria-labelledby="tagsAnnotationTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="tagsAnnotationTitle">
            {{ 'reports.addTagTitle' | translate }}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body" *ngIf="tagsQuestions && tagsQuestions.length">
          <form [formGroup]="tagsFormGroup" class="w-100">
            <div class="row">
              <div class="form-group col-12" *ngFor="let question of tagsQuestions">
                <mat-form-field class="example-full-width" *ngIf="question.inputType === 'text'">
                  <mat-label>{{ question.description }}</mat-label><!-- {{question.description}} -->
                  <input matInput id="{{ question.formControlName }}" formControlName="{{ question.formControlName }}"
                    [(ngModel)]="question.givenAns" [placeholder]="question.description" autocomplete="off" />
                </mat-form-field>

                <ng-select [placeholder]="question.description" id="{{ question.formControlName }}"
                  [multiple]="question.multiple === 'true' ? true : false"
                  [closeOnSelect]="question.multiple === 'true' ? false : true" [items]="question.suggestedAns"
                  bindLabel="label_3" bindValue="aid" formControlName="{{ question.formControlName }}"
                  [(ngModel)]="question.givenAns" *ngIf="question.inputType === 'select'"
                  (add)="addInGivenAns($event, question)" (clear)="clearItem($event, question)">
                  <ng-template ng-label-tmp let-item="item" let-clear="clear" *ngIf="question.multiple === 'true'">
                    <span class="ng-value-label">{{ item.label_3 }}</span>
                    <span class="ng-value-icon right" (click)="clearItem(item, question); clear(item)"
                      aria-hidden="true">×</span>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item">
                    {{ item.label_3 }}
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" id="closeTagBtn" class="btn btn-secondary" data-dismiss="modal">
            {{ 'common.closeBtnText' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>