import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ApiService } from '../../services/api.service';
import { TestService } from '../../services/test.service';
import { config } from '../../config';
import { AuthorisedUser, User, UserLinkingCode } from 'src/types';
import { UserService } from 'src/app/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-linked-account-information',
  templateUrl: './linked-account-information.component.html',
  styleUrls: ['./linked-account-information.component.css']
})
export class LinkedAccountInformationComponent implements OnInit {
  config = config;
  linkedAccountFormGroup: FormGroup;
  isLinkAccountLoad = true;
  userRole = JSON.parse(localStorage.getItem("roleId"));

  authorisedUsers: AuthorisedUser[] = [];
  userData: User;

  private subscription: Subscription = new Subscription();

  constructor(
    private dateFilter: DatePipe,
    private _testService: TestService,
    private _apiService: ApiService,
    private userService: UserService,
  ) {
    this.subscription.add(this.userService.getAuthorisedUserDataObservable().subscribe((d) => {
      this.authorisedUsers = d;
      this.isLinkAccountLoad = false;
    }));
    this.subscription.add(this.userService.getUserDataObservable().subscribe((d) => {
      this.userData = d;
    }));
  }

  ngOnInit() {
    if (this.userRole === 1001) {
      this.linkedAccountFormGroup = new FormGroup(
        {
          otpCode: new FormControl(''),
          validUntill: new FormControl('')
        }
      )
    }
  }

  /**
   * Patient Owner Linking Section (AFE-2410)
   */
  getOTP() {
    this
      ._apiService
      .generateUserLinkingCode(this.userData.id)
      .subscribe((response: UserLinkingCode) => {
        if (response && response.code && response.expirationDate) {
          this.linkedAccountFormGroup.setValue({ otpCode: response.code, validUntill: moment(moment.utc(response.expirationDate).toDate()).local().format("DD-MM-yyyy HH:mm:ss") });
        }
      }, (reason: any) => {
        console.log("Error :: getOTPForLinking :: ", reason);
      })
  }

  // remove linked Patient Account or Manager Account :: start
  async removeLinkedAccount(userId, firstName, lastName) {

    let message = "<p>You are about to remove access to your health data and profile information with the subscriber(s) listed below</p>";
    const subscriberInfo = `<p>${firstName} ${lastName}</p>`;
    message += `${subscriberInfo}<p>This change means that they will no longer have access to your special category data, particularly the data related to your health. Consequently, they will no longer be able to interpret test results, assess future test scheduling, or make decisions regarding any necessary treatment or intervention.</p>`;

    const result = await this.showWarningDialog(message);
    if (result.value) {
      this.userService.removeAuthorisedByUser(userId);

      Promise.all([
        this._testService.getTranslation('messages.delivered'),
        this._testService.getTranslation('messages.emailSentSuccess'),
        this._testService.getTranslation("common.okBtnText"),
        this._testService.getTranslation('messages.oops'),
        this._testService.getTranslation('messages.emailSentFail'),
      ]).then((keyResult: any) => {
        Swal.fire({
          type: "success",
          text: "Link removed successfully",
          timer: 2000,
          showConfirmButton: true,
          confirmButtonText: keyResult[2]
        });
        this.userService.fetchAuthorisedUserData();
      }).catch(async (error) => {
        const translation = await Promise.all([
          this._testService.getTranslation("common.okBtnText"),
          this._testService.getTranslation('messages.oops'),
        ]) as string[];
        Swal.fire({
          type: "error",
          title: translation[1] + "!!",
          text: "Link removed Fail. Please Try again!",
          timer: 2000,
          showConfirmButton: true,
          confirmButtonText: translation[0]
        });
      });
    } else {
      return false;
    }


  }

  async showWarningDialog(message) {
    return Swal.fire({
      type: 'warning',
      html: message,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#dc3545',
      confirmButtonText: "Remove Link",
    });
  }
}
