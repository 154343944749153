<div class="container-fluid" style="
    margin: 0px !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  ">
  <router-outlet style="position: absolute"></router-outlet>

  <div class="footer-content" *ngIf="!router.url.includes('auth')">
    <app-footer></app-footer>
  </div>
</div>