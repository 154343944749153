import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { commonConfig } from '../../../config/common/common';
import { ResultSetWithScores } from '../../../services/resultSet/types';
import { IRangeData, TColor } from '../../../services/score/types';
import { referenceRangeData } from '../smartReportData';
import { ScoreService } from '../../../services/score/score.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface IDataSet {
  data: number[]; // number of analytes in each category
  label: string;
  backgroundColor: string[];
  borderWidth: number;
}

interface IAnalyteReferenceData {
  analyteKeys: string[];
  referenceUnit: { [key: string]: string };
  referenceRange: { [key: string]: string };
}

interface IDonutChartData {
  chartId: string;
  immuneScore: string;
  datasets: IDataSet[];
  labels: TColor[];
  rangeData: IRangeData;
  analyteMapping: Record<string, string>; // analyte names Map
  analyteSequence: string[]; // list of analyte keys
  allRanges: IAnalyteReferenceData;
}

interface ISpeedOfChange {
  score: 1 | 2 | 3 | 4;
  level: string;
  text: string;
}

interface SanitizedSVG {
  text: string;
  svgContent: string;
  sanitizedContent: SafeHtml;
}

@Component({
  selector: 'app-score-details',
  templateUrl: './score-details.component.html',
  styleUrls: ['./score-details.component.css'],
})
export class ScoreDetailsComponent implements OnInit, OnChanges {
  @Input() selectedResultSet: ResultSetWithScores;
  @Input() previousResultSet?: ResultSetWithScores;
  @Input() isLoading: boolean;

  donutChartData: IDonutChartData;
  trendsIcons: SanitizedSVG[] = [
    {
      svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M4.66669 9.33333L8.00002 6L11.3334 9.33333H4.66669Z" fill="#A8A8A8"/>
</svg>`, text: "Test Result is higher than previous result",
      sanitizedContent: ""
    },
    {
      svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M4.66669 6.66663L8.00002 9.99996L11.3334 6.66663H4.66669Z" fill="#A8A8A8"/>

</svg>`, text: "Test Result is lower than previous result",
      sanitizedContent: ""
    },
    {
      svgContent: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <circle cx="8" cy="8" r="7.5" fill="white" stroke="#D2D2D2"/>
  <circle cx="8" cy="8" r="3" fill="#A8A8A8"/>
</svg>`, text: "Test Result is same as previous result",
      sanitizedContent: ""

    }
  ];
  referenceRangeColors = [
    { colorClass: "green", text: "Personal reference range" },
    { colorClass: "yellow", text: "Outside personal reference range" },
    { colorClass: "pink", text: "Outside population range" }
  ];
  rangeBarConfig: {
    min: number;
    max: number;
    current: number;
    offsetLeft: string;
  } = {
      min: 0,
      max: 10,
      current: 0,
      offsetLeft: '0%',
    };
  speedOfChange: ISpeedOfChange;

  constructor(private readonly scoreService: ScoreService, private sanitizer: DomSanitizer) {
    this.trendsIcons.forEach(svg => {
      svg.sanitizedContent = this.sanitizeSvg(svg.svgContent);
    });
  }


  ngOnInit(): void {
    if (this.previousResultSet) {
      this.setDonutChartData();
      this.setRangeBarConfig();
      this.setSpeedOfChange(
        this.selectedResultSet.nonAdaptiveScore,
        this.previousResultSet?.nonAdaptiveScore
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedResultSet?.currentValue) {
      this.setDonutChartData();
      this.setRangeBarConfig();
      this.setSpeedOfChange(
        this.selectedResultSet.nonAdaptiveScore,
        this.previousResultSet?.nonAdaptiveScore
      );
    }
  }

  sanitizeSvg(svgContent: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(svgContent);
  }

  setDonutChartData() {
    const rangeData = this.scoreService.calculateRangeData(
      this.selectedResultSet.adaptiveScores,
      this.selectedResultSet.results.analyteResults
    );
    const referenceRange = this.selectedResultSet.results.analyteResults.reduce(
      (result, next) => ({ ...result, [next.analyteKey]: next.referenceRange }),
      {}
    );
    const refinedRangeData = this._refineRangeDataToSingleColor(rangeData);
    const greenValues = Object.keys(refinedRangeData.green.value).length;
    const yellowValues = Object.keys(refinedRangeData.yellow.value).length;
    const redValues = Object.keys(refinedRangeData.red.value).length;

    this.donutChartData = {
      chartId: 'scoreChart',
      immuneScore: this.selectedResultSet.nonAdaptiveScore
        ? Number(this.selectedResultSet.nonAdaptiveScore).toFixed(2)
        : '--',
      datasets: [
        {
          data: [greenValues, yellowValues, redValues],
          label: '# of Cell Counts',
          backgroundColor: [
            commonConfig.colors.green,
            commonConfig.colors.yellow,
            commonConfig.colors.pink,
          ],
          borderWidth: 0,
        },
      ],
      labels: ['green', 'yellow', 'red'],
      rangeData: refinedRangeData,
      analyteMapping: referenceRangeData.dictionary,
      analyteSequence: this.selectedResultSet.essentialAnalytes,
      allRanges: {
        analyteKeys: referenceRangeData.analyteKeys,
        referenceRange,
        referenceUnit: referenceRangeData.referenceUnit,
      },
    };
  }

  setRangeBarConfig() {
    const current = this.selectedResultSet.nonAdaptiveScore;

    this.rangeBarConfig.current = current;
    this.rangeBarConfig.offsetLeft = current * 10 - current - 1 + '%';
  }

  getCurrentStatus(): string {
    return 'Health Values';
  }

  getLabel(type: 'start' | 'end'): string {
    if (type === 'start') {
      return 'Normal';
    }
    return 'Abnormal';
  }

  setSpeedOfChange(currentScore: number, previousScore: number = 0) {
    const diff = this._calculatePercentageDifference(
      previousScore,
      currentScore
    );

    if (diff <= 5) {
      this.speedOfChange = {
        score: 1,
        level: 'Stable',
        text: 'There is a minimal change to the score that is less than 5%',
      };
    } else if (diff <= 10 && diff > 5) {
      this.speedOfChange = {
        score: 2,
        level: 'Minor',
        text: 'There is a minor change to score that is between 5% and 10%',
      };
    } else if (diff <= 20 && diff > 10) {
      this.speedOfChange = {
        score: 3,
        level: 'Moderate',
        text: 'There is a moderate change to the score between 10% and 20%',
      };
    } else {
      this.speedOfChange = {
        score: 4,
        level: 'Major',
        text: ' There is a major change to the score that is more than 20%',
      };
    }
  }

  private _calculatePercentageDifference(
    previous: number,
    current: number
  ): number {
    if (!previous) {
      return 0;
    }
    const difference = current - previous;
    const percentageDifference = (difference / previous) * 100;
    return Math.abs(percentageDifference);
  }

  private _refineRangeDataToSingleColor(rangeData: IRangeData): IRangeData {
    const refinedRangeData: IRangeData = {
      green: { value: {}, high: {}, low: {}, trend: {} },
      yellow: { value: {}, high: {}, low: {}, trend: {} },
      red: { value: {}, high: {}, low: {}, trend: {} },
    };

    const priority = ['red', 'yellow', 'green']; // Priority order

    Object.keys(rangeData).forEach((color) => {
      ['value', 'high', 'low', 'trend'].forEach((property) => {
        Object.entries(rangeData[color][property]).forEach(
          ([analyteKey, value]) => {
            // Check if this analyte has already been assigned a higher-priority color
            const hasHigherPriority = priority
              .slice(0, priority.indexOf(color))
              .some(
                (higherPriorityColor) =>
                  refinedRangeData[higherPriorityColor][property][
                  analyteKey
                  ] !== undefined
              );

            // If not already assigned a higher-priority color, assign current color and clear others
            if (!hasHigherPriority) {
              refinedRangeData[color][property][analyteKey] = value;

              // Clear this analyte from lower-priority colors
              priority
                .slice(priority.indexOf(color) + 1)
                .forEach((lowerPriorityColor) => {
                  delete refinedRangeData[lowerPriorityColor][property][
                    analyteKey
                  ];
                });
            }
          }
        );
      });
    });

    return refinedRangeData;
  }
}
